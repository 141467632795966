import { auth } from "../firebase/firebase";
import axiosInstance from "./base";


export const getExhibition = async (id: number) => {
    const response = await axiosInstance.get(`/exhibitions/${id}`)
    return response.data;
}

export const getExhibitions = async () => {
    const response = await axiosInstance.get(`/exhibitions`)
    return response.data;
}

export const getExhibitionsShort = async () => {
    const response = await axiosInstance.get(`/exhibitions/short`)
    return response.data;
}

export const createExhibition = async (name: string, description: string, date: Date, location: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.post(`/exhibitions`, {
        name: name,
        date: date,
        location: location,
        description: description,
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteExhibition = async (id: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/exhibitions/${id}`, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const editExhibition = async (id: number, name: string, description: string, date: Date, location: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/exhibitions/${id}`, {
        name: name,
        date: date,
        location: location,
        description: description,
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const getClosestExhibition = async () => {
    const response = await axiosInstance.get(`/exhibitions/closest`)
    return response.data;
}

export const uploadExhibitionImages = async (images: (string | null)[], exhibitionId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.post(`/exhibitions/${exhibitionId}/images`, {
        images: images
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteExhibitionImage = async (exhibitionId: number, imageId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/exhibitions/${exhibitionId}/images/${imageId}`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const uploadExhibitionFiles = async (files: (string | null)[], exhibitionId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.post(`/exhibitions/${exhibitionId}/files`, {
        files: files
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteExhibitionFile = async (exhibitionId: number, fileId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/exhibitions/${exhibitionId}/files/${fileId}`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}