import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArticlesApi } from "../services";

interface CreateArticleMutationArgs {
    name: string,
    text: string
}

export const CreateArticleMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: CreateArticleMutationArgs) => ArticlesApi.createArticle(args.name, args.text),
        onError: (error) => {
            console.error('Failed to create article: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['articles']});
        },
    });
}

interface EditArticleMutationArgs {
    name: string,
    text: string,
    id: number
}

export const EditArticleMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditArticleMutationArgs) => ArticlesApi.editArticle(args.id, args.name, args.text),
        onError: (error) => {
            console.error('Failed to edit article: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['articles']});
        },
    });
}

export const DeleteArticleMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => ArticlesApi.deleteArticle(id),
        onError: (error) => {
            console.error('Failed to delete article: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['articles']});
        },
    });
}

interface ChangeArticleStatusArgs {
    id: number,
    status: boolean
}

export const ChangeArticleStatusMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: ChangeArticleStatusArgs) => ArticlesApi.changeArticleStatus(args.id, args.status),
        onError: (error) => {
            console.error('Failed to edit article status: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['articles']});
        },
    });
}

interface UploadArticleImagesArgs {
    articleId: number,
    images: (string | null)[]
}

export const UploadArticleImagesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: UploadArticleImagesArgs) => ArticlesApi.uploadArticleImages(args.images, args.articleId),
        onError: (error) => {
            console.error('Failed to upload article images: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['articles']});
        },
    });
}

interface DeleteArticleImageArgs {
    articleId: number,
    imageId: number
}

export const DeleteArticleImageMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: DeleteArticleImageArgs) => ArticlesApi.deleteArticleImage(args.articleId, args.imageId),
        onError: (error) => {
            console.error('Failed to delete article image: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['articles']});
        },
    });
}