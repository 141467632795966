interface AdminPageButtonProps {
    label: string,
    setSection: (number: number) => void,
    sectionToSet: number,
}

export const AdminPageButton: React.FC<AdminPageButtonProps> = ({ label, setSection, sectionToSet }) => {
    return (
        <button type="button" className="text-white py-3 px-3 text-xl hover:bg-[#DD7E3A]" onClick={() => setSection(sectionToSet)}>
            {label}
        </button>
    )
}