import { useForm } from "react-hook-form"
import { SubmitButton } from "../buttons/submitButton"
import { CreateArticleMutation } from "../../mutations/articles";
import { TextEditor } from "../textEditor";

interface AddArticleForm {
    name: string,
    text: string,
}

export const AddArticle = () => {
    const { register, watch } = useForm<AddArticleForm>();
    const addArticleMutation = CreateArticleMutation();

    function handleSubmit () {
        addArticleMutation.mutate({name: watch("name"), text: watch("text")});
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col space-y-2">
                <input className="border border-black p-2 w-full" placeholder="Nadpis" {...register("name")} />
                {/* <textarea className="border border-black p-2 w-full" placeholder="Text" {...register("text")}></textarea> */}
                <TextEditor register={register("text")} value={watch("text")} />
                <SubmitButton label="Vytvořit článek" event={handleSubmit} />
            </div>
        </div>
    )
}