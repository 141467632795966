import { useParams } from "react-router-dom";
import { Actuality } from "../models/actuality";
import { useQuery } from "@tanstack/react-query";
import { ActualitiesApi } from "../services";
import { ActualityLong } from "../components/actualities/actualityLong";

export const ActualityPage = () => {
    const { id } = useParams<{ id: string }>();
    const parsedId = id ? parseInt(id) : null;

    const { data: actuality, isLoading } = useQuery<Actuality>({
        queryKey: ['actualities', parsedId],
        queryFn: () => ActualitiesApi.getActuality(parsedId!),
        enabled: parsedId !== null,
    });

    if (isLoading) {
        return <div>Načítám...</div>;
    }

    if (!actuality) {
        return (
            <div>
                Aktualita nenalezena.
            </div>
        )
    }

    const date = new Date(actuality.createdAt);

    return (
        <div className="flex flex-col pb-5">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-5 md:p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-3xl xl:text-5xl">
                        {actuality?.name}
                    </div>
                    <div className="text-xl text-white z-10 mt-5">
                        {date.getDate()}.{date.toLocaleString('cs-CZ', { month: 'long' })} {date.getFullYear()}
                    </div>
                </div>
            </div>
            <div className="mt-3 px-3 text-justify lg:w-2/3 mx-auto">
                <ActualityLong actuality={actuality} />
            </div>
        </div>
    )
}