import { useState } from "react"
import { Exhibition } from "../../models/exhibition"
import { AddExhibition } from "./addExhibition"
import { ExhibitionInAdmin } from "./exhibitionInAdmin"
import { FormProvider, useForm } from "react-hook-form"
import { Reference } from "../../models/reference"
import { useQuery } from "@tanstack/react-query"
import { ExhibitionsApi } from "../../services"

export interface EditExhibitionFormValues {
    name: string,
    description: string,
    date: Date,
    location: string,
    references: Reference[],
    newReference: string,
    newReferenceName: string,
}

export const ExhibitionsManager = () => {
    const { data: exhibitionsData } = useQuery<Exhibition[]>({
        queryKey: ["exhibitions"],
        queryFn: ExhibitionsApi.getExhibitions,
    });
    const exhibitions = exhibitionsData ?? [];

    const [editedExhibition, setEditedExhibition] = useState<number | null>(null);
    const editForm = useForm<EditExhibitionFormValues>();

    return (
        <div className="flex flex-col">
            <div className="flex justify-center">
                <AddExhibition />
            </div>
            <div className="flex flex-col mx-auto mt-5 w-1/2 space-y-5">
                {
                    exhibitions.map((exhibiton, key) => (
                        <FormProvider {...editForm} >
                            <ExhibitionInAdmin exhibition={exhibiton} editedExhibition={editedExhibition} setEditedExhibition={setEditedExhibition} key={key} />
                        </FormProvider>
                    ))
                }
            </div>
        </div>
    )
}