export const AboutUs = () => {
    return (
        <div className="flex flex-col relative justify-center bg-fixed bg-[url('../public/hl21.jpg')] bg-cover bg-center py-10 px-3 sm:px-10 md:px-20 lg:px-40 xl:px-60 2xl:px-72">
            <div className="absolute inset-0 bg-black opacity-65"></div>
            <div className="text-center text-4xl text-white z-10">
                Kočky Brno
            </div>
            <div className="text-center text-xl mt-5 text-white z-10">
                Jsme specializovanou organizací Českého svazu chovatelů koček.
                Naším cílem je sdružit chovatele a majitele koček, osoby, které
                sdílejí stejné zájmy a respektují pravidla, slušné chování a ctí
                hodnoty. Jsme malou funkční organizací, řídíme se stanovami ČSCH,
                SCHK a FIFe, zavedeme pořádání propagačních výstav a přednáškovou
                činnost. Naším cílem je pokračovat v odkazu, který zde zanechal
                pan Ing. Bohumír Mahelka.
            </div>
        </div>
    )
}