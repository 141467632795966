import { auth } from "../firebase/firebase";
import axiosInstance from "./base";

// export const getArticles = async () => {
//     const token = await auth.currentUser?.getIdToken();
//     const response = await axiosInstance.get(`/articles/all`, {
//         headers: {
//             'Authorization': token
//         }
//     });
//     return response.data;
// }

export const createArticle = async (name: string, text: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.post(`/articles`, {
        name: name,
        text: text
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const getArticles = async () => {
    const response = await axiosInstance.get(`/articles`);
    return response.data;
}

export const editArticle = async (id: number, name: string, text: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/articles/${id}`, {
        name: name,
        text: text,
    }, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const deleteArticle= async (id: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/articles/${id}`, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const getAllArticle= async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.get(`/articles/all`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const changeArticleStatus = async (id: number, status: boolean) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/articles/${id}/status`, {
        status: status,
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const getArticle = async (id: number) => {
    const response = await axiosInstance.get(`/articles/${id}`);
    return response.data;
}

export const uploadArticleImages = async (images: (string | null)[], articleId: number) => {
    // const formData = new FormData();
    const token = await auth.currentUser?.getIdToken();
    // for (let i = 0; i < images.length; i++) {
    //     formData.append('images', images[i]);
    // }

    const response = await axiosInstance.post(`/articles/${articleId}/images`, {
        images: images,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteArticleImage = async (articleId: number, imageId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/articles/${articleId}/images/${imageId}`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}
