import { useQuery } from "@tanstack/react-query";
import { Actuality } from "../models/actuality";
import { ActualitiesApi } from "../services";
import { ActualityItem } from "../components/actualities/actualityItem";
import { useEffect, useMemo, useRef, useState } from "react";
import { Pagination } from "../components/pagination";

export const ActualitiesPage = () => {
    const { data: actualitiesData, isLoading, error } = useQuery<Actuality[]>({
        queryKey: ["actualities"],
        queryFn: ActualitiesApi.getActualities,
    });

    const [page, setPage] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    const itemsPerPage = 5;

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, [page]);

    const actualities = useMemo(() => actualitiesData ?? [], [actualitiesData]);

    const sortedActualities = useMemo(() => {
        return [...actualities].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }, [actualities]);

    const paginatedActualities = useMemo(() => {
        return sortedActualities.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
    }, [sortedActualities, page]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Chyba při načítání aktualit</div>;
    }

    return (
        <div className="flex flex-col pb-7">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-5xl">
                        Aktuality
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full lg:w-3/4 xl:w-2/3 mx-auto space-y-8 mt-7 px-3" ref={ref}>
                {paginatedActualities.map((actuality) => (
                    <ActualityItem actuality={actuality} key={actuality.actualityId} />
                ))}
                <div className="mx-5">
                    <Pagination setPage={setPage} page={page} itemsPerPage={itemsPerPage} numberOfItems={actualities.length} containerRef={ref} />
                </div>
            </div>
        </div>
    )
}
