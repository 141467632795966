import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ActualitiesApi } from "../services";

interface CreateActualityArgs {
    name: string,
    text: string,
    image: string,
}

export const CreateActualityMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: CreateActualityArgs) => ActualitiesApi.createActuality(args.name, args.text, args.image),
        onError: (error) => {
            console.error('Failed to create actuality: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['actualities']});
        },
    });
}

interface EditActualityArgs {
    name: string,
    text: string,
    id: number,
}

export const EditActualityMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditActualityArgs) => ActualitiesApi.editActuality(args.id, args.name, args.text),
        onError: (error) => {
            console.error('Failed to edit actuality: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['actualities']});
        },
    });
}

export const DeleteActualityMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => ActualitiesApi.deleteActuality(id),
        onError: (error) => {
            console.error('Failed to edit actuality: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['actualities']});
        },
    });
}


interface EditActualityImageArgs {
    actualityId: number,
    image: string,
    oldUrl: string,
}

export const EditActualityImageMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditActualityImageArgs) => ActualitiesApi.editActualityImage(args.image, args.actualityId, args.oldUrl),
        onError: (error) => {
            console.error('Failed to edit actuality image: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['actualities']});
        },
    });
}