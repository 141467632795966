import { useState } from "react"
import { AdminPageButton } from "../components/buttons/adminpageButton"
import { ActualityManager } from "../components/actualities/actualityManager";
import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "../services";
import { ExhibitionsManager } from "../components/exhibitions/exhibitionsManager";
import { UserManager } from "../components/users/userManager";
import { ArticlesManager } from "../components/articles/articlesManager";
import { FullUser } from "../models/fullUser";

export const Adminpage = () => {
    const [section, setSection] = useState(0);

    const { data: userData } = useQuery<FullUser>({
        queryKey: ["user"],
        queryFn: UsersApi.getCurrentUser,
    });
    const user = userData;

    if (!user) {
        return <div>404</div>
    }

    if (user?.role !== 1) {
        return <div>404</div>
    }

    return (
        <div className="px-5 pb-5">
            <div className="flex mt-5 bg-[#333333] justify-center">
                <AdminPageButton label="Aktuality" sectionToSet={1} setSection={setSection} />
                <AdminPageButton label="Výstavy" sectionToSet={2} setSection={setSection} />
                <AdminPageButton label="Uživatelé" sectionToSet={3} setSection={setSection} />
                <AdminPageButton label="Články" sectionToSet={4} setSection={setSection} />
            </div>
            <div className="mt-10">
                {
                    section === 1 &&
                    <div>
                        <ActualityManager />
                    </div>
                }
                {
                    section === 2 &&
                    <div>
                        <ExhibitionsManager />
                    </div>
                }
                {
                    section === 3 &&
                    <div>
                        <UserManager />
                    </div>
                }
                {
                    section === 4 &&
                    <div>
                        <ArticlesManager />
                    </div>
                }
            </div>
        </div>
    )
}