interface EditButtonProps {
    label: string,
    action: (edit: number | null) => void,
    editedExhibitionId: number | null,
    edit: number,
}

export const EditButton: React.FC<EditButtonProps> = ({ label, action, edit, editedExhibitionId }) => {
    return (
        <button type="button" className="bg-[#333333] hover:bg-[#DD7E3A] rounded p-3 text-white" onClick={() => action(editedExhibitionId === edit ? null : edit)}>
            {label}
        </button>
    )
}