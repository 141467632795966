import { Link } from "react-router-dom"
import { ExhibitionsApi } from "../../services";
import { Exhibition } from "../../models/exhibition";
import { useQuery } from "@tanstack/react-query";

export const ClosestExhibition = () => {
    const { data: closestExhibition } = useQuery<Exhibition>({
        queryKey: ["exhibitions"],
        queryFn: ExhibitionsApi.getClosestExhibition,
    });

    const closestExhibitionDate = closestExhibition ? new Date(closestExhibition.date) : null
    const closestExhibitionDateString = closestExhibitionDate ? closestExhibitionDate.getDate() + "." + (closestExhibitionDate.getMonth() + 1) + "." + closestExhibitionDate.getFullYear() : ""

    return (
        <Link to={closestExhibition?.exhibitionId ? "/exhibitions/" + closestExhibition?.exhibitionId : "/"}>
            <div className="flex flex-col relative bg-[url('../public/hl33.jpg')] bg-cover bg-center p-20 rounded-2xl">
                <div className="absolute inset-0 bg-black opacity-65 rounded-2xl"></div>
                <div className="flex flex-col text-center space-y-5">
                    <div className="text-white z-10 text-4xl">
                        NEJBLIŽŠÍ VÝSTAVA
                    </div>
                    <div className="text-white z-10 text-2xl">
                        {closestExhibitionDateString}
                    </div>
                    <div className="text-white z-10 text-2xl">
                        {closestExhibition?.location}
                    </div>

                </div>
            </div>
        </Link>
    )
}