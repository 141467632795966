// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAZUzfMM6jlZRWOv6Ii1UFCta_GwOJ_4Eo",
  authDomain: "kockybrno-4ff28.firebaseapp.com",
  databaseURL: "https://kockybrno-4ff28-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kockybrno-4ff28",
  storageBucket: "kockybrno-4ff28.appspot.com",
  messagingSenderId: "1066240034335",
  appId: "1:1066240034335:web:19ab92612163b405b3772a"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export const auth = getAuth(firebase);
export const storage = getStorage(firebase);
export default firebase