import { FormProvider, useForm } from "react-hook-form";
import { Actuality } from "../../models/actuality";
import { ActualityItemInAdmin } from "./actualityInAdmin";
import { AddActuality } from "./addActuality";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ActualitiesApi } from "../../services";

export interface EditActualityFormValues {
    name: string,
    text: string,
    image: FileList,
}

export const ActualityManager = () => {
    const { data: actualitiesData } = useQuery<Actuality[]>({
        queryKey: ["actualities"],
        queryFn: ActualitiesApi.getActualities,
    });
    const actualities = actualitiesData ?? [];

    const [showDeleteConfirm, setShowDeleteConfirm] = useState<number | null>(null);

    const [editedActuality, setEditedActuality] = useState<number | null>(null);
    const editForm = useForm<EditActualityFormValues>();

    return (
        <div className="flex flex-col">
            <div className="flex flex-col px-5">
                <div className="">
                    <AddActuality />
                </div>
            </div>
            <hr className="mt-8 bg-[#333333] h-1"></hr>
            <FormProvider {...editForm} >
                <div className="flex flex-col w-2/3 mx-auto space-y-8 mt-7">
                    {
                        actualities
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((actuality, key) => (
                            <ActualityItemInAdmin actuality={actuality} key={key} editedActuality={editedActuality} setEditedActuality={setEditedActuality} setShowDeleteConfirm={setShowDeleteConfirm} showDeleteConfirm={showDeleteConfirm} />
                        ))
                    }
                </div>
            </FormProvider>
        </div>
    )
}