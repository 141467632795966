import { useState } from "react"
import { ActionButton } from "../buttons/actionButton"
import { AddArticle } from "./addArticle";
import { Article } from "../../models/article";
import { ArticleInUser } from "./articleInUser";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { DeleteArticleMutation } from "../../mutations/articles";
import { Confirm } from "../confirm";
import { UsersApi } from "../../services";
import { useQuery } from "@tanstack/react-query";

export interface EditArticleForm {
    name: string,
    text: string,
}

export const ArticlesUserManager: React.FC = () => {
    const [addArticle, setAddArticle] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<number | null>(null);
    const [openedArticle, setOpenedArticle] = useState<number | null>(null);

    const { data: articlesData, isLoading } = useQuery<Article[]>({
        queryKey: ['articles'],
        queryFn: () => UsersApi.getUsersArticlesAll(),
    });

    const articles = articlesData ?? [];

    const formMethods = useForm<EditArticleForm>({
        defaultValues: {
            name: "",
            text: "",
        }
    })
    
    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <div className="flex flex-col">
            <div className="w-full md:w-3/5 mx-auto">
                <ActionButton action={setAddArticle} label="Přidat článek" status={addArticle} />
                <div className="mt-5">
                    {
                        addArticle &&
                        <AddArticle />
                    }
                </div>
                <FormProvider {...formMethods} >
                    <div className="space-y-5 mt-5">
                        {
                            articles
                                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                .map((article, key) => (
                                <ArticleItem article={article} key={key} setOpenedArticle={setOpenedArticle} openedArticle={openedArticle} setShowDeleteConfirm={setShowDeleteConfirm} showDeleteConfirm={showDeleteConfirm} />
                            ))
                        }
                    </div>
                </FormProvider>
            </div>
        </div>
    )
}

interface ArticleItemProps {
    article: Article,
    setOpenedArticle: (id: number | null) => void,
    openedArticle: number | null,
    showDeleteConfirm: number | null,
    setShowDeleteConfirm: (id: number | null) => void,
}

export const ArticleItem: React.FC<ArticleItemProps> = ({ article, setOpenedArticle, openedArticle, setShowDeleteConfirm, showDeleteConfirm }) => {
    const formMethods = useFormContext<EditArticleForm>();

    const deleteArticleMutation = DeleteArticleMutation();

    function handleDelete() {
        setShowDeleteConfirm(article.articleId);
    }

    function confirmDelete() {
        deleteArticleMutation.mutate(article.articleId);
    }

    return (
        <div>
            {
                showDeleteConfirm === article.articleId &&
                <Confirm action={confirmDelete} refuse={setShowDeleteConfirm} />
            }
            {/* <button className="bg-[#333333] p-5 text-white hover:bg-[#DD7E3A] rounded" onClick={() => setOpenedArticle(openedArticle === article.articleId ? null : article.articleId)}>{article.name}</button> */}
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-between">
                    <div className="font-bold text-2xl">
                        {article.name}
                    </div>
                    <div className="flex space-x-5 items-center">
                        <FaEdit onClick={() => setOpenedArticle(openedArticle === article.articleId ? null : article.articleId)} />
                        <button className="bg-[#DD7E3A] hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={() => handleDelete()}>
                            X
                        </button>
                    </div>
                </div>
            </div>
            {
                openedArticle === article.articleId &&
                <FormProvider {...formMethods} >
                    <ArticleInUser article={article} />
                </FormProvider>
            }
        </div>
    )

}