import { useQuery } from "@tanstack/react-query";
import { ExhibitionsApi } from "../services";
import { ShortExhibition } from "../models/shortExhibition";
import { ShortExhibitionItem } from "../components/exhibitions/shortExhibtionItem";

export const Exhibitions = () => {
    const { data: exhibitionsData, error, isLoading } = useQuery<ShortExhibition[]>({
        queryKey: ["exhibitions"],
        queryFn: ExhibitionsApi.getExhibitionsShort,
    });
    const exhibitions = exhibitionsData ?? [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Chyba při načítání výstav.</div>;
    }

    if (!Array.isArray(exhibitions)) {
        return <div>Data nejsou v požadovaném formátu.</div>;
    }

    return (
        <div className="flex flex-col space-y-5 pb-5">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-5xl">
                        Výstavy
                    </div>
                </div>
            </div>
            <div className="flex flex-col px-5 w-full md:w-2/3 mx-auto space-y-5">
                {
                    exhibitions?.map((exhibition, key) => (
                        <ShortExhibitionItem exhibition={exhibition} key={key} />
                    ))
                }
            </div>
        </div>
    )
}
