import Hamburger from "hamburger-react";
import { useState } from "react"
import { MenuButton } from "./menuButton";
import { AuthLine } from "./authLine";

export const Header = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="flex flex-col">
            <AuthLine />
            <div className={`flex flex-col md:flex-row justify-between w-full p-5`} >
                <div className="mx-auto">
                    <img src={process.env.PUBLIC_URL + "/logo_big-min.png"} alt="kockybrno" className="h-48" />
                </div>
                <div className="flex items-center text-5xl flex-grow ">
                    <div className="w-full text-center p-3 rounded-lg">
                        Kočky Brno
                    </div>
                </div>
                <div className="hidden lg:flex">
                    <img src={process.env.PUBLIC_URL + "/logo_big-min.png"} alt="kockybrno" className="h-48" />
                </div>
            </div>
            <div className="md:hidden w-full bg-[#DD7E3A]">
                <Hamburger toggled={isOpen} toggle={setOpen} color="white" />
            </div>
            <div className={`${!isOpen ? "hidden md:flex" : ""} flex flex-col md:flex-row w-full bg-[#DD7E3A] justify-center`}>
                <MenuButton label="Home" link="/" setOpen={setOpen} />
                <MenuButton label="Aktuality" link="/actualities" setOpen={setOpen} />
                <MenuButton label="Články" link="/articles" setOpen={setOpen} />
                <MenuButton label="Chov" link="/cats" setOpen={setOpen} />
                <MenuButton label="Výstavy" link="/exhibitions" setOpen={setOpen} />
                <MenuButton label="Členové" link="/members" setOpen={setOpen} />
            </div>
        </div>
    )
}
