import { FormProvider, useFormContext } from "react-hook-form"
import { Article } from "../../models/article"
import { EditArticleForm } from "./articlesUserManager";
import { useEffect } from "react";
import { SubmitButton } from "../buttons/submitButton";
import { DeleteArticleImageMutation, EditArticleMutation, UploadArticleImagesMutation } from "../../mutations/articles";
import { GalleryEditor } from "../galleryEditor";
import { TextEditor } from "../textEditor";

interface ArticleInUserProps {
    article: Article
}

export const ArticleInUser: React.FC<ArticleInUserProps> = ({ article }) => {
    const formMethods = useFormContext<EditArticleForm>();
    const { reset } = formMethods;
    const watch = formMethods.watch;

    const editArticleMutation = EditArticleMutation();

    function handleEdit() {
        editArticleMutation.mutate({ id: article.articleId, name: watch("name"), text: watch("text") });
    }

    const uploadArticleImagesMutation = UploadArticleImagesMutation();
    const deleteArticleImageMutation = DeleteArticleImageMutation();

    function upload(images: (string | null)[]) {
        uploadArticleImagesMutation.mutate({ articleId: article.articleId, images: images });
    }

    function handleDelete(imageId: number) {
        deleteArticleImageMutation.mutate({ articleId: article.articleId, imageId: imageId })
    }

    useEffect(() => {
        reset({
            name: article.name,
            text: article.text
        });
    }, [article, reset]);

    return (
        <div>
            <FormProvider {...formMethods}>
                <div className="space-y-5 mt-5">
                    <InputBox valueName="name" />
                    <TextareaBox />
                    <SubmitButton label="Upravit" event={handleEdit} />
                </div>
                <hr className="mt-3 bg-[#333333] h-1"></hr>
            </FormProvider>
            <GalleryEditor handleDelete={handleDelete} upload={upload} images={article.articleImages} />
        </div>
    )
}

interface InputBoxProps {
    valueName: keyof EditArticleForm;
}

export const InputBox: React.FC<InputBoxProps> = ({ valueName }) => {
    const { register } = useFormContext<EditArticleForm>();

    return (
        <div className="flex justify-center items-center">
            <input
                {...register(valueName)}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
        </div>
    );
};

export const TextareaBox: React.FC = () => {
    const { register, watch } = useFormContext<EditArticleForm>();

    return (
        <div className="flex justify-center items-center">
            {/* <textarea
                {...register(valueName)}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            /> */}
            <TextEditor register={register("text")} value={watch("text")} />
        </div>
    );
};

