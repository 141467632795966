import { Link } from "react-router-dom"
import { ShortArticle } from "../../models/shortArticle"

interface ArticleItemProps {
    article: ShortArticle
}

export const ArticleItem: React.FC<ArticleItemProps> = ({ article }) => {
    return (
        <Link to={`/articles/${article.articleId}`}>
            <div className="flex flex-col md:flex-row bg-[#333333] p-2  items-center justify-between">
                <div className="text-xl p-3 text-white text-center">
                    {article?.name}
                </div>
                <div className="text-xl p-3 text-white">
                    {article?.creator?.name} {article?.creator?.surname}
                </div>
            </div>
        </Link>
    )
}