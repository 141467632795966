import { ShortCat } from "../../models/shortCat";
import { Link } from "react-router-dom";

interface CatItemProps {
    cat: ShortCat
}

export const CatItem: React.FC<CatItemProps> = ({ cat }) => {
    return (
        <Link to={`/cats/${cat.catId}`} className="block hover:shadow-lg transition-shadow duration-300">
            <div className="flex bg-white shadow-md rounded-lg overflow-hidden">
                <div className="flex-shrink-0">
                    <img
                        src={cat.image}
                        alt="cat"
                        className="w-32 h-32 object-cover"
                    />
                </div>
                <div className="flex-grow p-4">
                    <div className="text-2xl font-semibold text-gray-800">
                        {cat?.name}
                    </div>
                    <div className="text-lg text-gray-600">
                        {cat?.owner?.name} {cat?.owner?.surname}
                    </div>
                </div>
            </div>
        </Link>
    )
}