import { FormProvider, useFormContext } from "react-hook-form";
import { Actuality } from "../../models/actuality"
import { EditActualityFormValues } from "./actualityManager";
import { useEffect } from "react";
import { SubmitButton } from "../buttons/submitButton";
import { EditActualityImageMutation, EditActualityMutation } from "../../mutations/actualities";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';
import { TextEditor } from "../textEditor";

interface ActualityEditorProps {
    actuality: Actuality
}

export const ActualityEditor: React.FC<ActualityEditorProps> = ({ actuality }) => {
    const formMethods = useFormContext<EditActualityFormValues>();
    const { reset, watch, register } = formMethods;

    const editActualityMutation = EditActualityMutation();
    const editActualityImageMutation = EditActualityImageMutation();
    // const addReferenceMutation = AddReferenceMutation();
    // const deleteReferenceMutation = DeleteReferenceMutation();

    function handleEdit() {
        editActualityMutation.mutate({ text: watch("text"), id: actuality.actualityId, name: watch("name") });
    }

    function handleEditImage() {
        if (watch("image").length === 0) {
            console.log("No file.");
            return;
        }

        const file = watch("image")[0];
        const randomFileName = `${uuidv4()}-${file.name}`
        console.log(randomFileName);
        const fileRef = ref(storage, randomFileName);

        uploadBytes(fileRef, file).then((snapshot) => {
            console.log('Image was uploaded:', snapshot);

            getDownloadURL(snapshot.ref).then((downloadURL) => {
                // Zde můžete volat mutaci pro aktualizaci URL obrázku v databázi
                // Příklad mutace nebyl definován, doplnění závisí na vaší implementaci
                editActualityImageMutation.mutate({ actualityId: actuality.actualityId, image: downloadURL, oldUrl: actuality.image })
            });
        }).catch((error) => {
            console.error("Upload error:", error);
        });
    }

    useEffect(() => {
        reset({
            name: actuality.name,
            text: actuality.text,
        });
    }, [reset, actuality]);

    return (
        <div className="space-y-5 flex flex-col">
            <FormProvider {...formMethods}>
                <InputBox valueName="name" valueText="" />
                {/* <img src={actuality.image} alt="cat" /> */}
                <div className="flex justify-center items-center">
                    {/* <textarea
                        {...(formMethods.register("text"))}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                    </textarea> */}
                    <div className="w-full">
                        <TextEditor register={register("text")} value={watch("text")} />
                    </div>
                </div>
                <img
                    src={actuality.image}
                    alt="cat"
                    className="w-48 h-48 object-cover rounded-2xl"
                />
                <div>
                    <input {...register("image")} type="file" accept=".png, .jpg, .jpeg, .gif" />
                    <SubmitButton label="upravit obrázek" event={handleEditImage} />
                </div>
                <SubmitButton label="Upravit" event={handleEdit} />
                <hr className="mt-8 bg-[#333333] h-1"></hr>
            </FormProvider>
        </div>
    )
}

interface InputBoxProps {
    valueName: keyof EditActualityFormValues;
    valueText: string;
}

export const InputBox: React.FC<InputBoxProps> = ({ valueName, valueText }) => {
    const { register } = useFormContext<EditActualityFormValues>();

    return (
        <div className="flex justify-center items-center">
            <input
                {...register(valueName)}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
        </div>
    );
};