import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UsersApi } from "../services";

interface CreateUserArgs {
    name: string,
    surname: string,
    email: string,
    password: string
}

export const AddUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: CreateUserArgs) => UsersApi.createUser(args.name, args.surname, args.email, args.password),
        onError: (error) => {
            console.error('Failed to create user: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['users']});
        },
    });
}

interface SetMembershipToUserArgs {
    userId: number,
    date: Date,
}

export const SetMembershipToUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: SetMembershipToUserArgs) => UsersApi.setMembershipToUser(args.userId, args.date),
        onError: (error) => {
            console.error('Failed to edit membership: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['users']});
        },
    });
}

interface EditUserArgs {
    userId: number,
    web: string,
    catKind: string
}

export const EditUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditUserArgs) => UsersApi.editUser(args.userId, args.web, args.catKind),
        onError: (error) => {
            console.error('Failed to edit user: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['users']});
        },
    });
}