import { FaPaperclip } from "@react-icons/all-files/fa/FaPaperclip";

export const DocumentsPage = () => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-5xl">
                        Dokumenty
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-2/3 mx-auto mt-5 space-y-5">
                <DocumentItem name="Potvrzení kočka" url={process.env.PUBLIC_URL + "/documents/potvrzeni-kocka.dot"} />
                <DocumentItem name="Potvrzení kocour" url={process.env.PUBLIC_URL + "/documents/potvrzeni-kocour.dot"} />
            </div>
        </div>
    )
}

interface DocumentItemProps {
    url: string,
    name: string
}

export const DocumentItem: React.FC<DocumentItemProps> = ({ name, url }) => {
    return (
        <a href={url} >
            <div className="flex space-x-5 bg-[#333333] text-white p-4 items-center">
                <FaPaperclip />
                <div>
                    {name}
                </div>
            </div>
        </a>
    )
}