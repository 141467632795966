import { Link } from "react-router-dom"
import { Header } from "./header/header"

export const Layout = (props: { children: React.ReactNode }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <div className="flex flex-col w-full">
                {props.children}
            </div>
            <div className="bg-[#DD7E3A] px-5 py-3 mt-auto flex space-x-5 items-center">
                <img src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" className="h-[50px]" />
                <Link to="https://logmeincats.azurewebsites.net/Visitor/Exhibition/Detail/2">ELEKTRONICKÁ PŘIHLÁŠKA</Link>
            </div>
        </div>
    )
}