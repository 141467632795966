import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReferencesApi } from "../services";

interface CreateReferenceArgs {
    name: string,
    reference: string,
    entityId: number,
    entity: number
}

export const AddReferenceMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: CreateReferenceArgs) => ReferencesApi.createReference(args.name, args.reference, args.entity, args.entityId),
        onError: (error) => {
            console.error('Failed to create reference: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

export const DeleteReferenceMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => ReferencesApi.deleteReference(id),
        onError: (error) => {
            console.error('Failed to delete reference: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}