import { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Context } from "../../App";
import { auth } from "../../firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { FullUser } from "../../models/fullUser";
import { UsersApi } from "../../services";

interface LoginForm {
    email: string,
    password: string,
}

export const AuthLine = () => {
    const [user, setUser] = useContext(Context);
    const [errorMsg, setErrorMsg] = useState("");

    const { register, handleSubmit, reset } = useForm<LoginForm>();

    const { data: userData } = useQuery<FullUser>({
        queryKey: ["u"],
        queryFn: UsersApi.getCurrentUser,
    });
    const currentUser = userData;

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth.signOut();
            setUser(null);
            console.log("SIGN OUT");
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    }

    const handleLogin = async (data: LoginForm) => {
        try {
            await signInWithEmailAndPassword(auth, data.email, data.password);
            setErrorMsg("");
            // console.log("Logged in");
            reset();
        } catch (error) {
            // setErrorMsg("Nesprávné přihlašovací údaje");
            console.log(error);
        }
    };

    return (
        <div className="flex bg-[#DD7E3A] p-3">
            {
                !user &&
                <form onSubmit={handleSubmit(handleLogin)} className="flex space-x-5">
                    <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5">
                        <input
                            className="p-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="E-Mail"
                            {...register("email")}
                        />
                        <input
                            className="p-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Heslo"
                            {...register("password")}
                            type="password"
                        />
                    </div>
                    <button className="p-2 bg-[#333333] text-white rounded-lg">
                        Přihlásit se
                    </button>
                    <div className="flex items-center">
                        <div>
                            {errorMsg}
                        </div>
                    </div>
                </form>
            }
            {
                user &&
                <div className="space-x-5">
                    <button className="p-2 bg-[#333333] text-white rounded-lg" onClick={() => handleLogout()}>
                        Odhlásit se
                    </button>
                    <Link to="/userpage">
                        <button className="p-2 bg-[#333333] text-white rounded-lg">
                            {user.email}
                        </button>
                    </Link>
                    {
                        currentUser?.role === 1 &&
                        <Link to="/adminpage">
                            <button className="p-2 bg-[#333333] text-white rounded-lg">
                                Adminpage
                            </button>
                        </Link>
                    }
                </div>
            }
        </div>
    )
}
