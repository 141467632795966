import { useQuery } from "@tanstack/react-query";
import { ShortArticle } from "../models/shortArticle";
import { ArticlesApi } from "../services";
import { useState } from "react";
import { ArticleItem } from "../components/articles/articleItem";

export const Articles = () => {
    const { data: articlesData, error, isLoading } = useQuery<ShortArticle[]>({
        queryKey: ["articles"],
        queryFn: ArticlesApi.getArticles,
    });

    const [search, setSearch] = useState("");

    if (isLoading) {
        return <div>Načítám...</div>;
    }

    if (error) {
        return <div>Články nelze načíst.</div>;
    }

    const articles = articlesData ?? [];

    const filteredArticles = articles.filter(article => {
        const filterCreator = (article.creator?.name?.toLowerCase() + " " + article.creator?.surname?.toLowerCase()).includes(search.toLowerCase());
        const filterName = article.name.toLowerCase().includes(search.toLowerCase());
        return filterCreator || filterName;
    })

    return (
        <div>
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-5xl">
                        Články
                    </div>
                </div>
            </div>
            <div className="flex flex-col space-y-5 md:w-2/3 mx-auto py-5 px-3">
                <input onChange={(e) => setSearch(e.target.value)} className="border border-black p-4" placeholder="Vyhledávač" />
                {
                    filteredArticles
                        .map((article, key) => (
                        <ArticleItem article={article} key={key} />
                    ))
                }
            </div>
        </div>
    )
}
