import { Link } from "react-router-dom"

interface ActualityProps {
    name: string,
    createdAt: Date,
    id: number,
}

export const ActualityShort: React.FC<ActualityProps> = ({ createdAt, name, id }) => {
    // function month(num: number) {
    //     return num === 0 ? "LED" : num === 1 ? "ÚNO" : num === 2 ? "BŘE" : num === 3 ? "DUB" : num === 4 ? "KVĚ" :
    //         num === 5 ? "ČVN" : num === 6 ? "ČVC" : num === 7 ? "SRP" : num === 8 ? "ZÁŘ" : num === 9 ? "ŘÍJ" : num === 10 ? "LIS" : "PRO"
    // }
    function month(num: number) {
        const months = ["LED", "ÚNO", "BŘE", "DUB", "KVĚ", "ČVN", "ČVC", "SRP", "ZÁŘ", "ŘÍJ", "LIS", "PRO"];
        return months[num];
    }

    return (
        <div>
            <Link to={`/actualities/${id}`}>
                <div className="flex bg-[#9E9696] p-2  items-center">
                    <div className="flex flex-col items-center justify-center bg-black rounded-full w-10 h-10 space-y-[-5px]">
                        <div className="text-white text-md text-center">
                            {new Date(createdAt).getDate()}
                        </div>
                        <div className="text-white text-xs text-center">
                            {month(new Date(createdAt).getMonth())}
                        </div>
                    </div>
                    <div className="text-xl p-3">
                        {name}
                    </div>
                </div>
            </Link>
        </div>
    )
}
