import { Actuality } from "../../models/actuality";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { ActualityEditor } from "./actualityEditor";
import { DeleteActualityMutation } from "../../mutations/actualities";
import { Confirm } from "../confirm";


interface ActualityProps {
    actuality: Actuality;
    setEditedActuality: (id: number | null) => void,
    editedActuality: number | null,
    setShowDeleteConfirm: (show: number | null) => void,
    showDeleteConfirm: number | null,
}

export const ActualityItemInAdmin: React.FC<ActualityProps> = ({ actuality, editedActuality, setEditedActuality, setShowDeleteConfirm, showDeleteConfirm }) => {
    function month(num: number) {
        return num === 0 ? "LED" : num === 1 ? "ÚNO" : num === 2 ? "BŘE" : num === 3 ? "DUB" : num === 4 ? "KVĚ" :
            num === 5 ? "ČVN" : num === 6 ? "ČVC" : num === 7 ? "SRP" : num === 8 ? "ZÁŘ" : num === 9 ? "ŘÍJ" : num === 10 ? "LIS" : "PRO"
    }

    const deleteActualityMutation = DeleteActualityMutation();

    function handleDelete() {
        setShowDeleteConfirm(actuality.actualityId);
    }

    function confirmDelete() {
        deleteActualityMutation.mutate(actuality.actualityId);
    }

    return (
        <div className="w-full">
            {
                showDeleteConfirm === actuality.actualityId &&
                <Confirm action={confirmDelete} refuse={setShowDeleteConfirm} />
            }
            <div className="flex w-full">
                {/* <div className="flex-shrink-0">
                    <img
                        src={process.env.PUBLIC_URL + "/cats/hl27.jpg"}
                        alt="img"
                        className="w-32 h-32 object-cover"
                    />
                </div> */}
                <div className="flex flex-col w-full">
                    <div className="flex items-center justify-between">
                        <div className="font-bold text-2xl">
                            {actuality.name}
                        </div>
                        <div className="flex space-x-5 items-center">
                            <div className="flex flex-col items-center justify-center bg-black rounded-full w-10 h-10 space-y-[-5px] ml-1">
                                <div className="text-white text-md text-center">
                                    {new Date(actuality.createdAt).getDate()}
                                </div>
                                <div className="text-white text-xs text-center">
                                    {month(new Date(actuality.createdAt).getMonth())}
                                </div>
                            </div>
                            <FaEdit onClick={() => setEditedActuality(editedActuality === actuality.actualityId ? null : actuality.actualityId)} />
                            <button className="bg-[#DD7E3A] hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={() => handleDelete()}>
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                {
                    actuality.actualityId === editedActuality &&
                    <ActualityEditor actuality={actuality} />
                }
            </div>
        </div>
    );
};
