interface ActionButtonProps {
    label: string,
    action: (open: boolean) => void,
    status: boolean
}

export const ActionButton: React.FC<ActionButtonProps> = ({ label, action, status }) => {
    return (
        <button type="button" className="bg-[#333333] rounded p-3 text-white" onClick={() => action(!status)}>
            {label}
        </button>
    )
}