import { Actuality } from "../../models/actuality"
import DOMPurify from 'dompurify';

interface ActualityLongProps {
    actuality: Actuality,
}

export const ActualityLong: React.FC<ActualityLongProps> = ({ actuality }) => {
    return (
        <div className="flex flex-col">
            <div className="flex space-x-5 mx-auto">
                <div className="flex-shrink-0">
                    <img
                        src={actuality.image}
                        alt={actuality.name}
                        className="w-full max-w-xs mx-auto object-cover rounded"
                    />
                </div>
            </div>
            <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(actuality.text) }}
            />
        </div>
    )
}