import { auth } from "../firebase/firebase";
import axiosInstance from "./base";

export const createUser = async (name: string, surname: string, email: string, password: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.post(`/users`, {
        name: name,
        surname: surname,
        email: email,
        password: password
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const getCurrentUser = async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.get(`/users/current`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const getUsersArticlesAll = async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.get(`/users/current/articles/all`, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const getUsers = async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.get(`/users`, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const setMembershipToUser = async (id: number, date: Date) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/users/${id}/editmembership`, {
        date: date
    }, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const editUser = async (userId: number, web: string, catKinds: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/users/${userId}`, {
        web: web,
        catKinds: catKinds
    }, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}