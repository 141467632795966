import { useQuery } from "@tanstack/react-query";
import { ShortCat } from "../models/shortCat";
import { CatsApi } from "../services";
import { useState } from "react";
import { CatItem } from "../components/cats/catItem";

export const Cats = () => {
    const { data: catsData, isLoading, error } = useQuery<ShortCat[]>({
        queryKey: ["cats"],
        queryFn: CatsApi.getCats,
    });

    const [search, setSearch] = useState("");

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Chyba při načítání koček</div>;
    }

    const cats = catsData ?? [];

    const filteredCats = cats?.filter(cat => {
        const searchLower = search.toLowerCase();
        const nameLower = cat?.name?.toLowerCase();
        const ownerNameLower = (cat?.owner?.name + " " + cat?.owner?.surname)?.toLowerCase();
        return nameLower.includes(searchLower) || ownerNameLower.includes(searchLower);
    })

    return (
        <div>
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-5xl">
                        Kočky
                    </div>
                </div>
            </div>

            <div className="flex flex-col space-y-5 md:w-2/3 mx-auto px-5 my-5">
                <input onChange={(e) => setSearch(e.target.value)} className="border border-black p-4 rounded" placeholder="Vyhledávač" />
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                    {
                        filteredCats.map((cat, key) => (
                            <CatItem cat={cat} key={key} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
