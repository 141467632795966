import React, { useState, createContext, Dispatch, SetStateAction, useEffect } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Layout } from './components/layout';
import { HomePage } from './pages/homepage';
import { Adminpage } from './pages/adminpage';
import { ActualitiesPage } from './pages/actualities';
import { ExhibitionPage } from './pages/exhibitionPage';
import { auth } from './firebase/firebase'
import { onAuthStateChanged, User } from 'firebase/auth';
import { Exhibitions } from './pages/exhibitions';
import { UserPage } from './pages/userPage';
import { ActualityPage } from './pages/actualityPage';
import { Articles } from './pages/articles';
import { ArticlePage } from './pages/articlePage';
import { Cats } from './pages/cats';
import { CatPage } from './pages/catPage';
import { Users } from './pages/users';
import { DocumentsPage } from './pages/documentsPage';

type UserContextType = [User | null, Dispatch<SetStateAction<User | null>>];
export const Context = createContext<UserContextType>([null, () => { }]);

function App() {
  // onAuthStateChanged(auth, (currentUser) => {
  //   setUser(currentUser);
  // })

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);  // Přidat stav načítání

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);  // Nastavte načítání na false po načtení stavu
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Zobrazí se, dokud není uživatel načten
  }

  return (
    <Context.Provider value={[user, setUser]} >
      <div className="w-screen h-screen">
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/adminpage' element={<Adminpage />} />
              <Route path='/actualities' element={<ActualitiesPage />} />
              <Route path='/actualities/:id' element={<ActualityPage />} />
              <Route path='/exhibitions' element={<Exhibitions />} />
              <Route path='/exhibitions/:id' element={<ExhibitionPage />} />
              <Route path='/userpage' element={<UserPage />} />
              <Route path='/articles' element={<Articles />} />
              <Route path='/articles/:id' element={<ArticlePage />} />
              <Route path='/cats' element={<Cats />} />
              <Route path='/cats/:id' element={<CatPage />} />
              <Route path='/members' element={<Users />} />
              <Route path='/documents' element={<DocumentsPage />} />
              {/* <Route path='/register' element={<Register />} />
              <Route path='/login' element={<Login />} />
              <Route path='/userpage' element={<UserPage />} /> */}
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
    </Context.Provider>
  );
}

export default App;
