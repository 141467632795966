import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "../services";
import { AdminPageButton } from "../components/buttons/adminpageButton";
import { useState } from "react";
import { ArticlesUserManager } from "../components/articles/articlesUserManager";
import { CatsManager } from "../components/cats/catsManager";
import { ChangePasswordForm } from "../components/users/changePasswordForm";
import { ChangeUserDetails } from "../components/users/changeUserDetails";
import { FullUser } from "../models/fullUser";

export const UserPage = () => {
    const { data: user, isLoading, isError } = useQuery<FullUser>({
        queryKey: ['users'],
        queryFn: () => UsersApi.getCurrentUser(),
    });

    const [section, setSection] = useState(0);

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Chyba při načítání uživatele.</div>
    }

    if (!user) {
        return (
            <div>
                Uživatel nenalezen.
            </div>
        )
    }

    return (
        <div className="flex flex-col">
            <div className="mt-5 text-3xl text-center">
                {user.name} {user.surname}
            </div>
            <div className="px-5 pb-5">
                <div className="flex flex-col md:flex-row mt-5 bg-[#333333] justify-center">
                    <AdminPageButton label="Články" sectionToSet={1} setSection={setSection} />
                    <AdminPageButton label="Kočky" sectionToSet={2} setSection={setSection} />
                    <AdminPageButton label="Heslo" sectionToSet={4} setSection={setSection} />
                    <AdminPageButton label="Detaily" sectionToSet={5} setSection={setSection} />
                </div>
                <div className="py-5">
                    {
                        section === 1 &&
                        <ArticlesUserManager />
                    }
                    {
                        section === 2 &&
                        <CatsManager />
                    }
                    {
                        section === 4 &&
                        <ChangePasswordForm />
                    }
                    {
                        section === 5 &&
                        <ChangeUserDetails user={user} />
                    }
                </div>
            </div>
        </div>
    )
}