import { useForm } from "react-hook-form"
import { auth } from "../../firebase/firebase";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { SubmitButton } from "../buttons/submitButton";
import { useState } from "react";

interface ChangePasswordValues {
    password: string,
    confirmPassword: string,
    oldPassword: string,
}

export const ChangePasswordForm = () => {
    const { register, watch, setError, clearErrors, formState: { errors } } = useForm<ChangePasswordValues>();
    const [message, setMessage] = useState("");

    const changePassword = async () => {
        const newPassword = watch("password");
        const confirmPassword = watch("confirmPassword");

        if (newPassword !== confirmPassword) {
            setError("confirmPassword", { type: "manual", message: "Hesla se neshodují." });
            return;
        }
        clearErrors("confirmPassword");

        const user = auth.currentUser;
        if (user) {  
            const credential = EmailAuthProvider.credential(
                user.email ?? "",
                watch("oldPassword")
            );

            try {
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, newPassword)
                console.log('Password updated successfully.');
                setMessage('Heslo bylo úspěšně změněno.'); 
            } catch (error) {
                setError("confirmPassword", { type: "manual", message: "Heslo nebylo změněno" });
                console.error('Error updating password:', error);
            }
        }
    };

    return (
        <div className="flex justify-center">
            <div className="flex flex-col w-full md:w-1/2 space-y-4">
                <input type="password" className="border border-black p-2 w-full" placeholder="Staré heslo" {...register("oldPassword")} />
                <input type="password" className="border border-black p-2 w-full" placeholder="Nové heslo" {...register("password")} />
                <input type="password" className="border border-black p-2 w-full" placeholder="Znovu nové heslo" {...register("confirmPassword")} />
                <SubmitButton label="Změnit heslo" event={changePassword} />
                {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword.message}</p>}
                <p className="text-green-500">{message}</p>
            </div>
        </div>
    )
}