import { useForm } from "react-hook-form";
import { CreateActualityMutation } from "../../mutations/actualities";
import { SubmitButton } from "../buttons/submitButton";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { v4 as uuidv4 } from 'uuid';
import { TextEditor } from "../textEditor";

interface AddActualityValues {
    name: string,
    text: string,
    image: FileList,
}

export const AddActuality = () => {
    const { register, watch, setValue } = useForm<AddActualityValues>();

    const createActualityMutation = CreateActualityMutation();

    const handleSubmit = () => {
        if (watch("image").length === 0) {
            console.log("No file.");
            return;
        }

        const file = watch("image")[0];
        const randomFileName = `${uuidv4()}-${file.name}`
        console.log(randomFileName);
        const fileRef = ref(storage, randomFileName);

        uploadBytes(fileRef, file).then((snapshot) => {
            console.log('Image was uploaded:', snapshot);

            getDownloadURL(snapshot.ref).then((downloadURL) => {
                // Zde můžete volat mutaci pro aktualizaci URL obrázku v databázi
                // Příklad mutace nebyl definován, doplnění závisí na vaší implementaci
                createActualityMutation.mutate({ name: watch("name"), text: watch("text"), image: downloadURL });
                setValue("name", "");
                setValue("text", "");
            });
        }).catch((error) => {
            console.error("Upload error:", error);
        });
    }

    return (
        <div className="flex justify-center">
            <div className="flex flex-col w-1/2 space-y-4">
                <input className="border border-black p-2 w-full" placeholder="Název" {...register("name")} />
                {/* <textarea className="border border-black p-2 w-full" placeholder="Text" {...register("text")} ></textarea> */}
                <TextEditor  register={register("text")} value={watch("text")} />
                <input {...register("image")} type="file" accept=".png, .jpg, .jpeg, .gif" />
                <SubmitButton event={handleSubmit} label="Přidat aktualitu" />
            </div>
        </div>
    )
}
