import { useForm } from "react-hook-form";
import { SubmitButton } from "../buttons/submitButton"
// import { auth } from "../../firebase/firebase";
// import { createUserWithEmailAndPassword } from "firebase/auth";
import { AddUserMutation } from "../../mutations/users";
import { useState } from "react";

interface AddUserForm {
    name: string,
    surname: string,
    email: string,
    password: string,
    confirmPassword: string,
}

export const AddUser = () => {
    const { register, watch } = useForm<AddUserForm>();
    const addUserMutation = AddUserMutation();
    const [error, setError] = useState("");

    const handleSubmit = async () => {
        try {
            // const password = generateRandomPassword();
            // await createUserWithEmailAndPassword(auth, watch("email"), "123456");
            if (watch("password") !== watch("confirmPassword")) {
                setError("Hesla se neshodují");
            } else {
                addUserMutation.mutate({ email: watch("email"), name: watch("name"), surname: watch("surname"), password: watch("password") });
                setError("")
            }
        } catch (error) {
            setError("Uživatel nebyl vytvořen.")
            console.log(error);
        }
    }

    return (
        <div className="flex flex-col w-1/2 space-y-4">
            <input className="border border-black p-2 w-full" placeholder="Jméno" {...register("name")} />
            <input className="border border-black p-2 w-full" placeholder="Příjmení" {...register("surname")} />
            <input className="border border-black p-2 w-full" placeholder="E-Mail" {...register("email")} />
            <input className="border border-black p-2 w-full" type="password" placeholder="Heslo" {...register("password")} />
            <input className="border border-black p-2 w-full" type="password" placeholder="Potvrzení hesla" {...register("confirmPassword")} />

            <SubmitButton event={handleSubmit} label="Přidat uživatele" />
            <div>
                {error}
            </div>
        </div>
    )
}