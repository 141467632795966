import { useQuery } from "@tanstack/react-query";
import { ShortActuality } from "../../models/shortActuality";
import { ActualitiesApi } from "../../services";
import { ActualityShort } from "../actualities/actualityShort";

export const NewActualities = () => {
    const { data: actualitiesData, isLoading, error } = useQuery<ShortActuality[]>({
        queryKey: ["actualities"],
        queryFn: ActualitiesApi.getNewActualities,
    });
    const actualities = actualitiesData ?? [];

    if (isLoading) return <div></div>

    if (error) return <div>Došlo k chybě při načítání aktualit.</div>;

    return (
        <div className="flex flex-col mx-auto">
            <div className="text-4xl">
                Aktuality
            </div>
            <div className="mt-5 space-y-5">
                {
                    actualities?.map((actuality) => (
                        <ActualityShort key={actuality.actualityId} name={actuality.name} createdAt={new Date(actuality.createdAt)} id={actuality.actualityId} />
                    ))
                }
            </div>
        </div>
    )
}