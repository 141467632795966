import { auth } from "../firebase/firebase";
import axiosInstance from "./base";

export const getActuality = async (id: number) => {
    const response = await axiosInstance.get(`/actualities/${id}`);
    return response.data;
}

export const getActualities = async () => {
    const response = await axiosInstance.get("/actualities");
    return response.data;
}

export const getNewActualities = async () => {
    const response = await axiosInstance.get("/actualities/new");
    return response.data;
}

export const createActuality = async (name: string, text: string, image: string) => {
    const token = await auth.currentUser?.getIdToken();
    // const formData = new FormData();
    // if (image.length > 0) {
    //     formData.append('image', image[0]);
    // }
    // formData.append('text', text);
    // formData.append('name', name);

    const response = await axiosInstance.post(`/actualities`, {
        text: text,
        name: name,
        image: image,
    }, {
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    });
    return response.data;
}

export const editActualityImage = async (image: string, actualityId: number, oldUrl: string) => {
    const token = await auth.currentUser?.getIdToken();
    // const formData = new FormData();
    // if (image.length > 0) {
    //     formData.append('file', image[0]);
    // }
    // formData.append('oldUrl', oldUrl);

    const response = await axiosInstance.put(`/actualities/${actualityId}/changeimage`, {
        oldUrl: oldUrl,
        image: image,
    }, {
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    });
    return response.data;
}

export const editActuality = async (id: number, name: string, text: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/actualities/${id}`, {
        name: name,
        text: text,
    }, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}

export const deleteActuality = async (id: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/actualities/${id}`, {
        headers: {
            'Authorization': token
        }
    })
    return response.data;
}