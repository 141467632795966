import { FormProvider, useFormContext } from "react-hook-form";
import { Exhibition } from "../../models/exhibition"
import { EditExhibitionFormValues } from "./exhibitionsManager";
import { useEffect, useState } from "react";
import { SubmitButton } from "../buttons/submitButton";
import { DeleteExhibitionFileMutation, DeleteExhibitionImageMutation, EditExhibitionMutation, UploadExhibitionFilesMutation, UploadExhibitionImagesMutation } from "../../mutations/exhibitions";
import { AddReferenceMutation, DeleteReferenceMutation } from "../../mutations/references";
import { GalleryEditor } from "../galleryEditor";
import { TextEditor } from "../textEditor";
import { FilesEditor } from "../filesEditor";

interface ExhibitionEditorProps {
    exhibition: Exhibition,
}

export const ExhibitionEditor: React.FC<ExhibitionEditorProps> = ({ exhibition }) => {
    const formMethods = useFormContext<EditExhibitionFormValues>();
    const { reset, setValue, register } = formMethods;
    const watch = formMethods.watch;

    const editExhibitionMutation = EditExhibitionMutation();
    const addReferenceMutation = AddReferenceMutation();
    const deleteReferenceMutation = DeleteReferenceMutation();
    const deleteExhibitionImageMutation = DeleteExhibitionImageMutation();
    const uploadExhibitionImageMutation = UploadExhibitionImagesMutation();
    const uploadExhibitionFileMutation = UploadExhibitionFilesMutation();
    const deleteExhibitionFileMutation = DeleteExhibitionFileMutation();

    function handleEdit() {
        editExhibitionMutation.mutate({ date: watch("date"), description: watch("description"), id: exhibition.exhibitionId, location: watch("location"), name: watch("name") });
    }

    function handleAddRef() {
        addReferenceMutation.mutate({ entity: 0, entityId: exhibition.exhibitionId, name: watch("newReferenceName"), reference: watch("newReference") });
        setValue("newReference", "");
        setValue("newReferenceName", "");
    }

    function handleDeleteRef(id: number) {
        deleteReferenceMutation.mutate(id);
    }

    function handleDeleteImage(imageId: number) {
        deleteExhibitionImageMutation.mutate({ exhibitionId: exhibition.exhibitionId, imageId: imageId });
    }

    function handleUploadImage(images: (string | null)[]) {
        uploadExhibitionImageMutation.mutate({ exhibitionId: exhibition.exhibitionId, images: images });
    }

    function handleUploadFile(files: (string | null)[]) {
        uploadExhibitionFileMutation.mutate({ exhibitionId: exhibition.exhibitionId, files: files });
    }

    function handleDeleteFile(fileId: number) {
        deleteExhibitionFileMutation.mutate({ exhibitionId: exhibition.exhibitionId, fileId: fileId })
    }

    useEffect(() => {
        reset({
            date: exhibition.date,
            description: exhibition.description,
            location: exhibition.location,
            name: exhibition.name,
            newReference: "",
        });
    }, [exhibition, reset]);

    const [newRef, setNewRef] = useState(false);

    return (
        <div className="space-y-5 flex flex-col">
            <FormProvider {...formMethods}>
                <InputBox valueName="name" valueText="" />
                <InputBox valueName="location" valueText="" />
                <div className="flex justify-center items-center">
                    <input
                        type="date"
                        {...register("date")}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                </div>
                <div className="flex justify-center items-center">
                    {/* <textarea
                        {...(formMethods.register("description"))}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                    </textarea> */}
                    <TextEditor register={register("description")} value={watch("description")} />
                </div>
                <div>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setNewRef(!newRef)}>+ URL</button>
                </div>
                {
                    newRef &&
                    <div className="flex space-x-4">
                        <div className="w-full space-y-3">
                            <input placeholder="URL" className="border border-black p-2 w-full" {...(formMethods.register)("newReference")} />
                            <input placeholder="Text" className="border border-black p-2 w-full" {...(formMethods.register)("newReferenceName")} />
                        </div>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleAddRef()}>+</button>
                    </div>
                }
                <div className="space-y-5">
                    {
                        exhibition?.references?.map((reference, key) => (
                            <div key={key} className="flex space-x-5 justify-between">
                                <div>
                                    {reference.name}
                                </div>
                                <div>
                                    {reference.reference}
                                </div>
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleDeleteRef(reference.referenceId)}>
                                    X
                                </button>
                            </div>
                        ))
                    }
                </div>
            </FormProvider>
            <GalleryEditor handleDelete={handleDeleteImage} images={exhibition.exhibitionImages} upload={handleUploadImage} />
            <FilesEditor upload={handleUploadFile} files={exhibition.exhibitionFiles} handleDelete={handleDeleteFile}  />
            <SubmitButton label="Upravit" event={handleEdit} />
        </div>
    )
}

interface InputBoxProps {
    valueName: keyof EditExhibitionFormValues;
    valueText: string;
}

export const InputBox: React.FC<InputBoxProps> = ({ valueName, valueText }) => {
    const { register } = useFormContext<EditExhibitionFormValues>();

    return (
        <div className="flex justify-center items-center">
            <input
                {...register(valueName)}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
        </div>
    );
};
