import { useFormContext } from "react-hook-form"
import { useEffect } from "react";
import { SubmitButton } from "../buttons/submitButton";
import { FullUser } from "../../models/fullUser";
import { EditUserForm } from "./userManager";
import { SetMembershipToUserMutation } from "../../mutations/users";

interface UserEditorProps {
    user: FullUser,
}

export const UserEditor: React.FC<UserEditorProps> = ({ user }) => {
    const formMethods = useFormContext<EditUserForm>();
    const { reset, register, watch } = formMethods;

    const setMembershipToUserMutation = SetMembershipToUserMutation();

    useEffect(() => {
        reset({
            membershipExpire: user.membershipExpire,
        });
    }, [user, reset]);

    function handleEdit() {
        setMembershipToUserMutation.mutate({date: watch("membershipExpire"), userId: user.userId})
    }

    return (
        <div className="space-y-5 flex flex-col">
            <div className="flex justify-center items-center">
                <input
                    type="date"
                    {...register("membershipExpire")}
                    className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
            </div>
            <SubmitButton label="Upravit" event={handleEdit} />
            <hr className="mt-8 bg-[#333333] h-1"></hr>
        </div>
    )
}