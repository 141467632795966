import { useState } from "react";
import { ActionButton } from "../buttons/actionButton";
import { AddCat } from "./addCat";
import { Cat } from "../../models/cat";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { CatEditor } from "./catEditor";
import { Confirm } from "../confirm";
import { DeleteCatMutation } from "../../mutations/cats";
import { useQuery } from "@tanstack/react-query";
import { CatsApi } from "../../services";

export interface EditCatForm {
    name: string,
    birthday: Date,
    kind: string,
    ems: string,
    location: string,
    tests: string,
    page: string,
    description: string,
    image: FileList
}

export const CatsManager: React.FC = () => {
    const [addCat, setAddCat] = useState(false);
    const [openedCat, setOpenedCat] = useState<number | null>(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<number | null>(null);

    const { data: catsData, isLoading, isError } = useQuery<Cat[]>({
        queryKey: ['cats'],
        queryFn: () => CatsApi.getUsersCats(),
    });
    const cats = catsData ?? [];

    const formMethods = useForm<EditCatForm>({
        defaultValues: {
            location: "",
            tests: "",
            page: "",
            description: "",
            name: "",
            birthday: new Date(),
            kind: "",
            ems: ""
        }
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Chyba při načítání koček</div>
    }

    return (
        <div className="flex flex-col">
            <div className="w-full md:w-3/5 mx-auto">
                <ActionButton action={setAddCat} label="Přidat kočku" status={addCat} />
                <div className="mt-5">
                    {
                        addCat &&
                        <AddCat />
                    }
                </div>
                <FormProvider {...formMethods} >
                    <div className="mt-3">
                        {
                            cats
                                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                .map((cat, key) => (
                                    <CatItem cat={cat} key={key} openedCat={openedCat} setOpenedCat={setOpenedCat} setShowDeleteConfirm={setShowDeleteConfirm} showDeleteConfirm={showDeleteConfirm} />
                                ))
                        }
                    </div>
                </FormProvider>
            </div>
        </div>
    )
}

interface CatItemProps {
    cat: Cat,
    setOpenedCat: (id: number | null) => void,
    openedCat: number | null,
    setShowDeleteConfirm: (id: number | null) => void,
    showDeleteConfirm: number | null
}

export const CatItem: React.FC<CatItemProps> = ({ cat, setOpenedCat, openedCat, setShowDeleteConfirm, showDeleteConfirm }) => {
    const formMethods = useFormContext<EditCatForm>();

    const deleteCatMutation = DeleteCatMutation();

    function handleDelete() {
        setShowDeleteConfirm(cat.catId);
    }

    function confirmDelete() {
        deleteCatMutation.mutate(cat.catId);
    }

    return (
        <div>
            {
                showDeleteConfirm === cat.catId &&
                // eslint-disable-next-line react/jsx-no-undef
                <Confirm action={confirmDelete} refuse={setShowDeleteConfirm} />
            }
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-between">
                    <div className="font-bold text-2xl">
                        {cat.name}
                    </div>
                    <div className="flex space-x-5 items-center">
                        <FaEdit onClick={() => setOpenedCat(openedCat === cat.catId ? null : cat.catId)} />
                        <button className="bg-[#DD7E3A] hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={() => handleDelete()}>
                            X
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-5">
            {
                openedCat === cat.catId &&
                <FormProvider {...formMethods} >
                    <CatEditor cat={cat} />
                </FormProvider>
            }
            </div>
        </div>
    )
}
