import { Link } from "react-router-dom";
import { Actuality } from "../../models/actuality";

interface ActualityProps {
    actuality: Actuality;
}

export const ActualityItem: React.FC<ActualityProps> = ({ actuality }) => {
    return (
        <div className="p-5">
            <Link to={`/actualities/${actuality.actualityId}`} className="block hover:shadow-lg transition-shadow duration-300">
                <div className="sm:relative flex flex-col sm:flex-row bg-white shadow-md rounded-lg overflow-hidden sm:h-40">
                    <div className="sm:w-1/3 flex justify-center items-center">
                        <img
                            src={actuality.image}
                            alt={actuality.name}
                            className="object-cover w-full h-full rounded-l-lg"
                        />
                    </div>
                    <div className="flex sm:w-2/3 p-5">
                        <div className="font-bold text-xl lg:text-2xl 2xl:text-3xl mb-2">
                            {actuality.name}
                        </div>
                        <div className="sm:absolute sm:bottom-5 sm:right-5 bg-gray-800 rounded-full w-12 h-12 flex flex-col items-center justify-center">
                            <div className="text-white text-md">
                                {new Date(actuality.createdAt).getDate()}
                            </div>
                            <div className="text-white text-xs">
                                {new Date(actuality.createdAt).toLocaleString('cs-CZ', { month: 'short' }).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
    
};
