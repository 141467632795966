import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ExhibitionsApi } from "../services";

interface CreateExhibitionArgs {
    name: string,
    description: string,
    date: Date,
    location: string
}

export const AddExhibitionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: CreateExhibitionArgs) => ExhibitionsApi.createExhibition(args.name, args.description, args.date, args.location),
        onError: (error) => {
            console.error('Failed to create exhibition: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

export const DeleteExhibitionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => ExhibitionsApi.deleteExhibition(id),
        onError: (error) => {
            console.error('Failed to delete exhibition: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

interface EditExhibitionArgs {
    id: number,
    name: string,
    description: string,
    date: Date,
    location: string
}

export const EditExhibitionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditExhibitionArgs) => ExhibitionsApi.editExhibition(args.id, args.name, args.description, args.date, args.location),
        onError: (error) => {
            console.error('Failed to edit exhibition: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

interface UploadExhibitionImagesArgs {
    exhibitionId: number,
    images: (string | null)[],
}

export const UploadExhibitionImagesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: UploadExhibitionImagesArgs) => ExhibitionsApi.uploadExhibitionImages(args.images, args.exhibitionId),
        onError: (error) => {
            console.error('Failed to upload exhibition images: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

interface DeleteExhibitionImageArgs {
    exhibitionId: number,
    imageId: number
}

export const DeleteExhibitionImageMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: DeleteExhibitionImageArgs) => ExhibitionsApi.deleteExhibitionImage(args.exhibitionId, args.imageId),
        onError: (error) => {
            console.error('Failed to delete exhibition image: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

interface UploadExhibitionFilesArgs {
    exhibitionId: number,
    files: (string | null)[],
}

export const UploadExhibitionFilesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: UploadExhibitionFilesArgs) => ExhibitionsApi.uploadExhibitionFiles(args.files, args.exhibitionId),
        onError: (error) => {
            console.error('Failed to upload exhibition files: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}

interface DeleteExhibitionFileArgs {
    exhibitionId: number,
    fileId: number
}

export const DeleteExhibitionFileMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: DeleteExhibitionFileArgs) => ExhibitionsApi.deleteExhibitionFile(args.exhibitionId, args.fileId),
        onError: (error) => {
            console.error('Failed to delete exhibition file: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['exhibitions']});
        },
    });
}