import { auth } from "../firebase/firebase";
import axiosInstance from "./base";

export const getCats = async () => {
    const response = await axiosInstance.get(`/cats`);
    return response.data;
}

export const getCat = async (id: number) => {
    const response = await axiosInstance.get(`/cats/${id}`);
    return response.data;
}

export const getUsersCats = async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.get(`/cats/mycats`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteCat = async (id: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/cats/${id}`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const createCat = async (name: string, birthday: Date, kind: string, ems: string, location: string, tests: string, page: string, description: string, image: string) => {
    const token = await auth.currentUser?.getIdToken();

    // const formData = new FormData();
    // if (image.length > 0) {
    //     formData.append('image', image[0]);
    // }
    // formData.append('name', name);
    // formData.append('kind', kind);
    // formData.append('ems', ems);
    // formData.append('location', location);
    // formData.append('tests', tests);
    // formData.append('page', page);
    // formData.append('description', description);
    // formData.append('birthday', birthday.toString());

    const response = await axiosInstance.post(`/cats`, {
        name: name,
        kind: kind,
        ems: ems,
        location: location,
        tests: tests,
        page: page,
        description: description,
        birthday: birthday.toString(),
        image: image
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const editCat = async (id: number, name: string, birthday: Date, kind: string, ems: string, location: string, tests: string, page: string, description: string) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.put(`/cats/${id}`, {
        name: name,
        birthday: birthday,
        kind: kind,
        ems: ems,
        location: location,
        tests: tests,
        page: page,
        description: description,
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const editCatImage = async (image: string, catId: number, oldUrl: string) => {
    // const formData = new FormData();
    const token = await auth.currentUser?.getIdToken();
    // if (image.length > 0) {
    //     formData.append('image', image[0]);
    // }
    // formData.append('oldUrl', oldUrl);

    const response = await axiosInstance.put(`/cats/${catId}/changeimage`, {
        image: image,
        oldUrl: oldUrl,
    }, {
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    });
    return response.data;
}

export const uploadCatImages = async (images: (string | null)[], catId: number) => {
    // const formData = new FormData();
    const token = await auth.currentUser?.getIdToken();
    // for (let i = 0; i < images.length; i++) {
    //     formData.append('images', images[i]);
    // }
    // formData.append('catId', catId.toString());

    const response = await axiosInstance.post(`/cats/${catId}/images`, {
        images: images
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteCatImage = async (catId: number, imageId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/cats/${catId}/images/${imageId}`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}
