import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useForm } from "react-hook-form";
import { storage } from "../firebase/firebase";
import { EntityFile } from "../models/entityFile";
import { v4 as uuidv4 } from 'uuid';
import { SubmitButton } from "./buttons/submitButton";

interface AddFilesForm {
    files: FileList,
}

interface FilesEditorProps {
    files: EntityFile[],
    handleDelete: (fileId: number) => void,
    upload: (files: (string | null)[]) => void,
}

export const FilesEditor: React.FC<FilesEditorProps> = ({ files, handleDelete, upload }) => {
    const { register, watch } = useForm<AddFilesForm>();

    function handleUpload() {
        const files = watch("files");
        if (files.length === 0) {
            console.log("No files.");
            return;
        }

        // Convert FileList to Array to use map
        const uploadPromises = Array.from(files).map((file) => {
            const randomFileName = `${uuidv4()}-${file.name}`;
            console.log(randomFileName);
            const fileRef = ref(storage, randomFileName);

            return uploadBytes(fileRef, file)
                .then((snapshot) => getDownloadURL(snapshot.ref))
                .catch((error) => {
                    console.error("Upload error for file:", file.name, error);
                    return null;  // Return null if there's an error, so this file won't be included in the mutation.
                });
        });

        Promise.all(uploadPromises).then((urls) => {
            // Filter out any nulls in case some uploads failed.
            const validUrls = urls.filter(url => url !== null);
            if (validUrls.length > 0) {
                // Zde můžete volat mutaci pro aktualizaci URL obrázků v databázi
                upload(validUrls);
            } else {
                console.log("No images were successfully uploaded.");
            }
        }).catch((error) => {
            console.error("Error in uploading one or more files:", error);
        });
    }

    const extractFileName = (url: string) => {
        const regex = /\/o\/([^?]+)/;
        const match = url.match(regex);
        let fullFileName = match ? match[1] : null;
        if (fullFileName) {
            const parts = fullFileName.split('-');
            fullFileName = parts.slice(-2).join('-');
        }
        return fullFileName;
    }

    return (
        <div className="flex flex-col">
            <div className="flex justify-between">
                <div>
                    <input type="file" className="w-full" placeholder="Testy" {...register("files")} multiple accept=".pdf" />
                </div>
                <SubmitButton label="Nahrát soubory" event={handleUpload} />
            </div>
            <div className="flex flex-col mt-4 space-y-5">
                {files?.map((file, key) => (
                    <div key={key} className="flex space-x-1">
                        <a href={file.fileName}>
                            {extractFileName(file.fileName)}
                        </a>
                        <button
                            onClick={() => handleDelete(file.exhibitionFileId)}
                            className="bg-red-500 text-white p-1"
                        >
                            X
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}
