import axios from 'axios';

const axiosInstance = axios.create({
    // baseURL: "http://localhost:4000",
    baseURL: "https://api-5rhbrenzrq-uc.a.run.app",
    // baseURL: process.env.BE_HOST,
    // headers: {
    //     "Access-Control-Allow-Credentials": true,
    // },
    withCredentials: true
});

export default axiosInstance;
