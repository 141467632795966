interface PaginationProps {
    page: number,
    setPage: React.Dispatch<React.SetStateAction<number>>,
    itemsPerPage: number,
    numberOfItems: number,
    containerRef: React.RefObject<HTMLDivElement>,
}

export const Pagination: React.FC<PaginationProps> = ({ page, setPage, itemsPerPage, numberOfItems, containerRef }) => {
    const handleNextPage = () => {
        setPage(page + 1);
        containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handlePreviousPage = () => {
        if (page > 0) {
            setPage(page - 1);
            containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const disabledPrev = page === 0;
    const disabledNext = numberOfItems <= (page + 1) * itemsPerPage;

    return (
        <div className='mt-5 flex justify-between'>
            <PageButton buttonText="Prev" handleClick={handlePreviousPage} disabled={disabledPrev} />
            <PageButton buttonText="Next" handleClick={handleNextPage} disabled={disabledNext} />
        </div>
    )
}

interface PageButtonProps {
    handleClick: () => void,
    buttonText: string,
    disabled: boolean,
}

export const PageButton: React.FC<PageButtonProps> = ({ handleClick, buttonText, disabled }) => {
    return (
        <button className="bg-[#333333] hover:bg-[#DD7E3A] text-white font-bold py-2 px-4"
                onClick={handleClick}
                disabled={disabled}>
                {buttonText}
        </button>
    )
}