import { RoundButton } from "../buttons/roundButton"
import ImageSources from '../../assets/catsImages.json'
import { Image } from '../../models/image';
import { useEffect, useMemo, useState } from "react";

export const Presentation = () => {
    const images: Image[] = ImageSources;
    const [frame, setFrame] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFrame(frame < 3 ? frame + 1 : 0);
        }, 5000);

        return () => clearInterval(intervalId);
    });

    function generateRandomNumbers(): number[] {
        const count = 16;
        const max = ImageSources.length;
        let randomNumbers: number[] = [];

        for (let i = 0; i < count; i++) {
            let randomNumber = Math.floor(Math.random() * max);
            randomNumbers.push(randomNumber);
        }

        return randomNumbers;
    }

    const imageIndexes = useMemo(() => generateRandomNumbers(), []);

    return (
        <div className="flex flex-col px-3">
            <div className="grid grid-rows-2 grid-cols-2 lg:grid-rows-1 lg:grid-cols-4 mt-10 mx-auto gap-10">
                {
                    imageIndexes.slice(frame * 4, (frame + 1) * 4).map((index, key) => (
                        <div key={key}>
                            <img src={process.env.PUBLIC_URL + "/cats/" + images[index].source} alt="cat_img" />
                        </div>
                    ))
                }
            </div>
            <div className="flex space-x-5 mt-10 mx-auto">
                <RoundButton setFunction={setFrame} valueToSet={0} currentValue={frame} />
                <RoundButton setFunction={setFrame} valueToSet={1} currentValue={frame} />
                <RoundButton setFunction={setFrame} valueToSet={2} currentValue={frame} />
                <RoundButton setFunction={setFrame} valueToSet={3} currentValue={frame} />
            </div>
        </div>
    )
}