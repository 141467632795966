import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CatsApi } from "../services";

interface CreateCatArgs {
    name: string,
    birthday: Date,
    kind: string,
    ems: string,
    location: string,
    tests: string,
    page: string,
    description: string,
    image: string,
}

export const CreateCatMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: CreateCatArgs) => CatsApi.createCat(args.name, args.birthday, args.kind, args.ems, args.location, args.tests, args.page, args.description, args.image),
        onError: (error) => {
            console.error('Failed to create cat: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cats']});
        },
    });
}

interface EditCatArgs {
    id: number,
    name: string,
    birthday: Date,
    kind: string,
    ems: string,
    location: string,
    tests: string,
    page: string,
    description: string
}

export const EditCatMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditCatArgs) => CatsApi.editCat(args.id, args.name, args.birthday, args.kind, args.ems, args.location, args.tests, args.page, args.description),
        onError: (error) => {
            console.error('Failed to edit cat: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cats']});
        },
    });
}

export const DeleteCatMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => CatsApi.deleteCat(id),
        onError: (error) => {
            console.error('Failed to delete cat: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cats']});
        },
    });
}

interface EditCatImageArgs {
    catId: number,
    image: string,
    oldUrl: string,
}

export const EditCatImageMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: EditCatImageArgs) => CatsApi.editCatImage(args.image, args.catId, args.oldUrl),
        onError: (error) => {
            console.error('Failed to edit cat image: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cats']});
        },
    });
}

interface UploadCatImagesArgs {
    catId: number,
    images: (string | null)[]
}

export const UploadCatImagesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: UploadCatImagesArgs) => CatsApi.uploadCatImages(args.images, args.catId),
        onError: (error) => {
            console.error('Failed to upload cat images: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cats']});
        },
    });
}

interface DeleteCatImageArgs {
    catId: number,
    imageId: number
}

export const DeleteCatImageMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: DeleteCatImageArgs) => CatsApi.deleteCatImage(args.catId, args.imageId),
        onError: (error) => {
            console.error('Failed to delete cat image: ', error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cats']});
        },
    });
}