import { Exhibition } from "../../models/exhibition"
import { DeleteExhibitionMutation } from "../../mutations/exhibitions";
import { EditButton } from "../buttons/editButton"
import { ExhibitionEditor } from "./exhibitionEditor"

interface ExhibitionInAdminProps {
    exhibition: Exhibition,
    setEditedExhibition: (id: number | null) => void,
    editedExhibition: number | null,
}

export const ExhibitionInAdmin: React.FC<ExhibitionInAdminProps> = ({ exhibition, editedExhibition, setEditedExhibition }) => {
    const addExhibitionMutation = DeleteExhibitionMutation();

    function handleDelete() {
        addExhibitionMutation.mutate(exhibition.exhibitionId);
    }

    return (
        <div className="flex flex-col border border-black p-5 relative">
            <div className="font-bold text-2xl text-center">
                {exhibition.name}
            </div>
            <div className="text-md text-center mt-3 text-center">
                {new Date(exhibition.date).toDateString()}
            </div>
            <div className="text-md mt-3">
                {exhibition.location}
            </div>
            <div className="text-md mt-3">
                {exhibition.description}
            </div>
            <div className="mt-5">
                <EditButton action={setEditedExhibition} edit={exhibition.exhibitionId} label="Upravit výstavu" editedExhibitionId={editedExhibition} />
            </div>
            <div className="mt-5">
                {
                    editedExhibition === exhibition.exhibitionId &&
                    <ExhibitionEditor exhibition={exhibition} />
                }
            </div>
            <button className="absolute bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleDelete()}>
                X
            </button>
        </div>
    )
}