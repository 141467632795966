import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Exhibition } from "../models/exhibition";
import { ExhibitionsApi } from "../services";
import { Reference } from "../models/reference";
import DOMPurify from 'dompurify';

export const ExhibitionPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: exhibition, isLoading } = useQuery<Exhibition>({
        queryKey: ['exhibitions', id],
        queryFn: () => ExhibitionsApi.getExhibition(parseInt(id ?? "0")),
        enabled: !!id,
    });

    if (isLoading) {
        return <div>Loading...</div> 
    }

    if (!exhibition) {
        return (
            <div>
                Chyba při načtění výstavy.
            </div>
        )
    }

    const date = new Date(exhibition.date);

    const numberOfImages = exhibition.exhibitionImages.length;

    const extractFileName = (url: string) => {
        const regex = /\/o\/([^?]+)/;
        const match = url.match(regex);
        let fullFileName = match ? match[1] : null;
        if (fullFileName) {
            const parts = fullFileName.split('-');
            fullFileName = parts.slice(-2).join('-');
        }
        return fullFileName;
    }

    return (
        <div className="flex flex-col pb-5">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-3xl xl:text-5xl">
                        {exhibition.name}
                    </div>
                </div>
            </div>
            <div className="mt-3 text-center text-2xl font-bold">
                {date.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', year: 'numeric' })}
            </div>
            <div className="mt-3 text-center text-xl font-bold">
                {exhibition.location}
            </div>
            <div className="mt-3 text-justify px-5 md:px-0 md:w-2/3 mx-auto" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(exhibition.description) }} />
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${numberOfImages < 4 ? numberOfImages : 4} mt-10 mx-auto gap-10 px-5`}>
                {
                    exhibition.exhibitionImages.map((image, key) => (
                        <div key={key}>
                            <div className="flex-shrink-0">
                                <img
                                    src={image.image}
                                    alt="img"
                                    className="w-full max-w-xs mx-auto object-cover rounded"
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="mx-auto mt-5 space-y-3">
                {
                    exhibition?.references?.map((ref, key) => (
                        <ExhibitionReference key={key} reference={ref} />
                    ))
                }
            </div>
            <div className="mx-auto mt-5 space-y-3">
                {
                    exhibition?.exhibitionFiles?.map((file, key) => (
                        <div>
                            <a href={file.fileName}>
                                <div className="bg-[#333333] hover:bg-[#DD7E3A] p-2 rounded">
                                    <div className="text-white text-center">
                                        {extractFileName(file.fileName)}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

interface ExhibitionReferenceProps {
    reference: Reference
}

export const ExhibitionReference: React.FC<ExhibitionReferenceProps> = ({ reference }) => {
    return (
        <div>
            <a href={reference.reference}>
                <div className="bg-[#333333] hover:bg-[#DD7E3A] p-2 rounded">
                    <div className="text-white text-center">
                        {reference.name}
                    </div>
                </div>
            </a>
        </div>
    )
}