import { useForm } from "react-hook-form"
import { EditUserMutation } from "../../mutations/users";
import { FullUser } from "../../models/fullUser";
import { useEffect } from "react";

interface ChangeUserDetailsProps {
    user: FullUser,
}

interface UserDetailsArgs {
    web: string,
    catKinds: string
}

export const ChangeUserDetails: React.FC<ChangeUserDetailsProps> = ({ user }) => {
    const { register, watch, reset } = useForm<UserDetailsArgs>();

    const editUserMutation = EditUserMutation();

    function handleSubmit () {
        editUserMutation.mutate({catKind:watch("catKinds"), web: watch("web"), userId: user.userId })
    }

    useEffect(() => {
        reset({
            catKinds: user.catKinds,
            web: user.web,
        });
    }, [user, reset]);

    return (
        <div className="flex justify-center">
            <div className="flex flex-col w-full md:w-1/2 space-y-4">
                <input className="border border-black p-2 w-full" placeholder="Web" {...register("web")} />
                <input className="border border-black p-2 w-full" placeholder="Druhy koček" {...register("catKinds")} />
                <button className="bg-[#333333] hover:bg-[#DD7E3A] text-white py-2 px-2" onClick={handleSubmit}>Upravit</button>
            </div>
        </div>
    )
}