import { ShortExhibition } from "../../models/shortExhibition";
import { Link } from "react-router-dom";

interface ShortExhibitionProps {
    exhibition: ShortExhibition
}

export const ShortExhibitionItem: React.FC<ShortExhibitionProps> = ({ exhibition }) => {
    const date = new Date(exhibition.date);

    return (
        <Link to={`/exhibitions/${exhibition.exhibitionId}`}>
            <div className="flex flex-col md:flex-row bg-[#333333] justify-between p-3 items-center">
                <div className="text-center md:text-left text-white text-lg md:text-xl lg:text-2xl">
                    {exhibition.name}
                </div>
                <div>
                    <div className="text-white text-center md:text-right text-sm md:text-lg">
                        {exhibition.location}
                    </div>
                    <div className="text-white text-center md:text-right text-sm md:text-lg">
                        {date.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                    </div>
                </div>
            </div>
        </Link>
    )
}
