import { useForm } from "react-hook-form";
import { SubmitButton } from "../buttons/submitButton"
import { AddExhibitionMutation } from "../../mutations/exhibitions";
import { TextEditor } from "../textEditor";

interface AddExhibitionForm {
    name: string,
    description: string,
    date: Date,
    location: string
}

export const AddExhibition = () => {
    const { register, watch } = useForm<AddExhibitionForm>();
    const addExhibitionMutation = AddExhibitionMutation();

    function handleSubmit () {
        addExhibitionMutation.mutate({date: watch("date"), description: watch("description"), location: watch("location"), name: watch("name") })
    }

    return (
        <div className="flex flex-col w-1/2 space-y-4">
            <input className="border border-black p-2 w-full" placeholder="Název" {...register("name")} />
            <input className="border border-black p-2 w-full" placeholder="Místo" {...register("location")} />
            <input type="date" className="border border-black p-2 w-full" placeholder="Datum" {...register("date")} />
            {/* <textarea className="border border-black p-2 w-full" placeholder="Popis" {...register("description")} ></textarea> */}
            <TextEditor register={register("description")} value={watch("description")} />
            <SubmitButton event={handleSubmit} label="Přidat výstavu" />
        </div>
    )
}