import { FormProvider, useFormContext } from "react-hook-form";
import { Cat } from "../../models/cat"
import { EditCatForm } from "./catsManager";
import { useEffect } from "react";
import { SubmitButton } from "../buttons/submitButton";
import { DeleteCatImageMutation, EditCatImageMutation, EditCatMutation, UploadCatImagesMutation } from "../../mutations/cats";
import { GalleryEditor } from "../galleryEditor";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase";

interface CatEditorProps {
    cat: Cat,
}

export const CatEditor: React.FC<CatEditorProps> = ({ cat }) => {
    const formMethods = useFormContext<EditCatForm>();
    const { reset, watch, register } = formMethods;

    const editCatMutation = EditCatMutation();
    const editCatImageMutation = EditCatImageMutation();
    const deleteCatImageMutation = DeleteCatImageMutation();
    const uploadCatImagesMutation = UploadCatImagesMutation();

    function handleEdit() {
        editCatMutation.mutate({ birthday: watch("birthday"), description: watch("description"), ems: watch("ems"), id: cat.catId, kind: watch("kind"), location: watch("location"), name: watch("name"), page: watch("page"), tests: watch("tests") });
    }

    function handleEditImage() {
        if (watch("image").length === 0) {
            console.log("No file.");
            return;
        }

        const file = watch("image")[0];
        const randomFileName = `${uuidv4()}-${file.name}`
        console.log(randomFileName);
        const fileRef = ref(storage, randomFileName);

        uploadBytes(fileRef, file).then((snapshot) => {
            console.log('Image was uploaded:', snapshot);

            getDownloadURL(snapshot.ref).then((downloadURL) => {
                // Zde můžete volat mutaci pro aktualizaci URL obrázku v databázi
                // Příklad mutace nebyl definován, doplnění závisí na vaší implementaci
                editCatImageMutation.mutate({ catId: cat.catId, image: downloadURL, oldUrl: cat.image })
            });
        }).catch((error) => {
            console.error("Upload error:", error);
        });
    }

    function upload(images: (string | null)[]) {
        uploadCatImagesMutation.mutate({ catId: cat.catId, images: images });
    }

    function handleDelete(imageId: number) {
        deleteCatImageMutation.mutate({ catId: cat.catId, imageId: imageId })
    }

    useEffect(() => {
        reset({
            name: cat.name,
            birthday: cat.birthday,
            description: cat.description,
            ems: cat.ems,
            kind: cat.kind,
            location: cat.location,
            page: cat.page,
            tests: cat.tests,
        });
    }, [reset, cat]);

    return (
        <div className="space-y-5 flex flex-col">
            <FormProvider {...formMethods}>
                <InputBox valueName="name" valueText="" />
                <div className="flex justify-center items-center">
                    <input
                        type="date"
                        {...register("birthday")}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                </div>
                {/* <InputBox valueName="birthday" valueText="" /> */}
                <InputBox valueName="ems" valueText="" />
                <InputBox valueName="kind" valueText="" />
                <InputBox valueName="location" valueText="" />
                <InputBox valueName="page" valueText="" />
                <InputBox valueName="tests" valueText="" />
                <div className="flex justify-center items-center">
                    <textarea
                        {...(formMethods.register("description"))}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                    </textarea>
                </div>
                <div className="flex justify-between">
                    <div>
                        <input type="file" className="w-full" placeholder="Testy" {...register("image")} accept=".png, .jpg, .jpeg, .gif" />
                    </div>
                    <SubmitButton label="Upravit obrázek" event={handleEditImage} />
                </div>
                <GalleryEditor images={cat.catImages} handleDelete={handleDelete} upload={upload} />
                <SubmitButton label="Upravit" event={handleEdit} />
                <hr className="mt-8 bg-[#333333] h-1"></hr>
            </FormProvider>
        </div>
    )
}

interface InputBoxProps {
    valueName: keyof EditCatForm;
    valueText: string;
}

export const InputBox: React.FC<InputBoxProps> = ({ valueName, valueText }) => {
    const { register } = useFormContext<EditCatForm>();

    return (
        <div className="flex justify-center items-center">
            <input
                {...register(valueName)}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
        </div>
    );
};