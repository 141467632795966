import { useForm } from "react-hook-form";
import { SubmitButton } from "../buttons/submitButton";
import { CreateCatMutation } from "../../mutations/cats";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase";

interface AddCatForm {
    name: string,
    birthday: Date,
    kind: string,
    ems: string,
    location: string,
    tests: string,
    page: string,
    description: string,
    image: FileList,
}

export const AddCat = () => {
    const { register, watch } = useForm<AddCatForm>();
    const createCatMutation = CreateCatMutation();

    function handleSubmit() {
        if (watch("image").length === 0) {
            console.log("No file.");
            return;
        }

        const file = watch("image")[0];
        const randomFileName = `${uuidv4()}-${file.name}`
        console.log(randomFileName);
        const fileRef = ref(storage, randomFileName);

        uploadBytes(fileRef, file).then((snapshot) => {
            console.log('Image was uploaded:', snapshot);

            getDownloadURL(snapshot.ref).then((downloadURL) => {
                // Zde můžete volat mutaci pro aktualizaci URL obrázku v databázi
                // Příklad mutace nebyl definován, doplnění závisí na vaší implementaci
                createCatMutation.mutate({
                    birthday: watch("birthday"), description: watch("description"), ems: watch("ems"),
                    kind: watch("kind"), location: watch("location"), name: watch("name"), page: watch("page"), tests: watch("tests"), image: downloadURL
                })
            });
        }).catch((error) => {
            console.error("Upload error:", error);
        });
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col space-y-2">
                <input className="border border-black p-2 w-full" placeholder="Jméno" {...register("name")} />
                <input type="date" className="border border-black p-2 w-full" placeholder="Narozeniny" {...register("birthday")} />
                <input className="border border-black p-2 w-full" placeholder="Druh" {...register("kind")} />
                <input className="border border-black p-2 w-full" placeholder="EMS" {...register("ems")} />
                <input className="border border-black p-2 w-full" placeholder="Stanice" {...register("location")} />
                <input className="border border-black p-2 w-full" placeholder="Stránka" {...register("page")} />
                <input className="border border-black p-2 w-full" placeholder="Testy" {...register("tests")} />
                <textarea className="border border-black p-2 w-full" placeholder="Text" {...register("description")}></textarea>
                <input type="file" className="w-full" placeholder="Image" {...register("image")} accept=".png, .jpg, .jpeg, .gif" />
                <SubmitButton label="Vytvořit kočku" event={handleSubmit} />
            </div>
        </div>
    )
}