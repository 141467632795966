interface ConfirmProps {
    action: () => void,
    refuse: (status: number | null) => void,
}

export const Confirm: React.FC<ConfirmProps> = ({ action, refuse }) => {
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col bg-white p-5">
                <div>
                    Opravdu chcete smazat?
                </div>
                <div className="flex space-x-5 mt-3 mx-auto">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => action()}>
                        X
                    </button>
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => refuse(null)}>
                        NE
                    </button>
                </div>
            </div>
        </div>
    )
}