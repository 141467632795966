import { AboutUs } from "../components/homepage/aboutUs";
import { Presentation } from "../components/homepage/presentation";
import { ClosestExhibition } from "../components/homepage/closestExhibiton";
import { NewActualities } from "../components/homepage/newActualities";
import { SponsorsPresentation } from "../components/homepage/sponsorsPresentation";

export const HomePage = () => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-col lg:flex-row py-10 bg-[#D9D9D9] px-3">
                <div className="flex w-full lg:w-1/2">
                    <NewActualities />
                </div>
                <div className="w-full lg:w-1/2 mt-10 lg:mt-0 flex justify-center">
                    <ClosestExhibition />
                </div>
            </div>
            <AboutUs />
            <Presentation />
            <div className="flex py-10">
                <SponsorsPresentation />
            </div>
        </div>
    )
}