import { Link } from "react-router-dom"

interface MenuButtonProps {
    label: string,
    link: string,
    setOpen: (opened: boolean) => void,
}

export const MenuButton: React.FC<MenuButtonProps> = ({ label, link, setOpen }) => {
    return (
        <Link to={link}>
            <button className="text-2xl text-white py-3 px-6 hover:bg-[#333333] text-center w-full md:w-auto" onClick={() => setOpen(false)}>
                {label}
            </button>
        </Link>
    )
}