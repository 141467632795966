interface RoundButtonProps {
    setFunction: (number: number) => void,
    valueToSet: number,
    currentValue: number,
}

export const RoundButton: React.FC<RoundButtonProps> = ({setFunction, currentValue, valueToSet}) => {
    return (
        <button className={`w-8 h-8 ${currentValue !== valueToSet ? "bg-red-200": "bg-[#DD7E3A]"} hover:bg-[#DD7E3A] rounded-full`} onClick={() => setFunction(valueToSet)} />
    )
}