import { auth } from "../firebase/firebase";
import axiosInstance from "./base";

export const createReference = async (name: string, reference: string, entity: number, entityId: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.post(`/references`, {
        name: name,
        reference: reference,
        entity: entity, 
        entityId: entityId
    }, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}

export const deleteReference = async (id: number) => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axiosInstance.delete(`/references/${id}`, {
        headers: {
            'Authorization': token
        }
    });
    return response.data;
}