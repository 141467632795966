import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CatsApi } from "../services";
import { Cat } from "../models/cat";

export const CatPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: cat, isLoading } = useQuery<Cat>({
        queryKey: ['exhibitions', id],
        queryFn: () => CatsApi.getCat(parseInt(id ?? "0")),
        enabled: !!id,
    });

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!cat) {
        return (
            <div>
                Kočka neexistuje.
            </div>
        )
    }

    const date = new Date(cat.birthday);

    return (
        <div className="flex flex-col items-center w-2/3 mx-auto">
            <div className="flex flex-col md:flex-row mt-5 space-x-10 mx-auto">
                <div className="flex-shrink-0 mx-auto">
                    <img
                        src={cat.image}
                        alt="cat"
                        className="w-48 h-48 object-cover rounded-2xl"
                    />
                </div>
                <div className="flex flex-col mt-5 md:mt-0 mx-auto">
                    <CatDetail name="Jméno:" value={cat.name} />
                    <CatDetail name="Narozen:" value={date.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', year: 'numeric' })} />
                    <CatDetail name="Druh:" value={cat.kind} />
                    <CatDetail name="EMS:" value={cat.ems} />
                    <CatDetail name="Majitel:" value={cat.owner.name + " " + cat.owner.surname} />
                    <CatDetail name="Stanice:" value={cat.location} />
                    <CatDetail name="Testy:" value={cat.tests} />
                    <CatDetail name="Webstránka:" value={cat.page} />
                </div>
            </div>
            <div className="mt-5">
                {cat.description}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5 mb-5">
                {
                    cat.catImages.map((image, key) => (
                        <img src={image.image} key={key} alt="cat" />
                    ))
                }
            </div>
        </div>
    )
}

interface CatDetailProps {
    name: string,
    value: string
}
 
export const CatDetail: React.FC<CatDetailProps> = ({ name, value }) => {
    return (
        <div className="">
            <div className="font-bold">
                {name}
            </div>
            <div>
                {value}
            </div>
        </div>
    )
}