interface SubmitButtonProps {
    label: string,
    event: () => void,
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ event, label}) => {
    return (
        <button onClick={() => event()} className="bg-[#333333] hover:bg-[#DD7E3A] text-white py-2 px-2">
            {label}
        </button>
    )
}