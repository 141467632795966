import Sponsors from '../../assets/sponsors.json'
import { useEffect, useState } from "react";
import { Sponsor } from "../../models/sponsor";

export const SponsorsPresentation = () => {
    const sponsors: Sponsor[] = Sponsors;
    const [sponsorFrame, setSponsorFrame] = useState(Math.floor(Math.random() * sponsors.length));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSponsorFrame(Math.floor(Math.random() * sponsors.length));
        }, 5000);
    
        return () => clearInterval(intervalId);
    }, [sponsors.length]);

    return (
        <img
            className="mx-auto h-40 object-contain px-3"
            src={`${process.env.PUBLIC_URL}/sponsors/${sponsors[sponsorFrame].source}`}
            alt="sponsor"
        />
    )
}