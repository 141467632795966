import { UseFormRegisterReturn } from 'react-hook-form';
import {
  BtnBold,
  BtnItalic,
  BtnLink,
  BtnUnderline,
  BtnStrikeThrough,
  BtnClearFormatting,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';

interface TextEditorProps {
  register: UseFormRegisterReturn,
  value: string,
}

export const TextEditor: React.FC<TextEditorProps> = ({ register, value }) => {
  return (
    // <div className='w-full'>
      <EditorProvider>
        <Editor value={value} {...register}>
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <BtnClearFormatting />
            <BtnLink />
          </Toolbar>
        </Editor>
      </EditorProvider>
    // </div>
  );
}