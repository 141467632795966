import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ArticlesApi } from "../services";
import { Article } from "../models/article";
import DOMPurify from "dompurify";

export const ArticlePage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: article, isLoading } = useQuery<Article>({
        queryKey: ['articles', id],
        queryFn: () => ArticlesApi.getArticle(parseInt(id ?? "0")),
        enabled: !!id,
    });

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!article) {
        return (
            <div>
                Článek nenalezen.
            </div>
        )
    }

    const date = new Date(article.createdAt);

    return (
        <div className="flex flex-col pb-5">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 pb-10 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-3xl xl:text-5xl">
                        {article.name}
                    </div>
                    <div className="text-white z-10 text-2xl mt-6">
                        {article.creator.name} {article.creator.surname}
                    </div>
                </div>
            </div>
            <div className="mt-3 text-center text-2xl font-bold">
                {date.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', year: 'numeric' })}
            </div>
            <div className="mt-3 text-justify w-2/3 mx-auto whitespace-pre-line" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.text) }} />
            <div className="px-3 grid grid-rows-2 grid-cols-2 lg:grid-rows-1 lg:grid-cols-4 mt-10 mx-auto gap-10">
                {
                    article.articleImages.map((image, key) => (
                        <img src={image.image} key={key} alt="article" />
                    ))
                }
            </div>
        </div>
    )
}
