import { useEffect, useState } from "react"
import { Article } from "../../models/article"
import { ChangeArticleStatusMutation, EditArticleMutation } from "../../mutations/articles";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { SubmitButton } from "../buttons/submitButton";
import { useQuery } from "@tanstack/react-query";
import { ArticlesApi } from "../../services";
import { TextEditor } from "../textEditor";

interface ArticleEditorValues {
    text: string,
    name: string,
}

export const ArticlesManager = () => {
    const { data: articlesData } = useQuery<Article[]>({
        queryKey: ["articles"],
        queryFn: ArticlesApi.getAllArticle,
    });
    const articles = articlesData ?? [];

    const [openedArticle, setOpenedArticle] = useState<number | null>(null);
    const formMethods = useForm<ArticleEditorValues>();

    return (
        <div className="flex flex-col w-2/3 mx-auto space-y-5">
            <FormProvider {...formMethods} >
                {
                    articles
                        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                        .map((article, key) => (
                            <ArticleItem article={article} key={key} openedArticle={openedArticle} setOpenedArticle={setOpenedArticle} />
                        ))
                }
            </FormProvider>
        </div>
    )
}

interface ArticleProps {
    article: Article,
    openedArticle: number | null,
    setOpenedArticle: (id: number | null) => void,
}

export const ArticleItem: React.FC<ArticleProps> = ({ article, openedArticle, setOpenedArticle }) => {
    const { register, watch, reset } = useFormContext<ArticleEditorValues>();

    const changeArticleStatusMutation = ChangeArticleStatusMutation();

    function handleStatusChange(status: boolean) {
        changeArticleStatusMutation.mutate({ id: article.articleId, status: status });
    }

    const editArticleMutation = EditArticleMutation();

    function handleEdit() {
        editArticleMutation.mutate({ id: article.articleId, name: watch("name"), text: watch("text") });
    }

    useEffect(() => {
        reset({
            name: article.name,
            text: article.text
        });
    }, [article, reset]);

    return (
        <div className={`p-5 flex flex-col ${article.approved ? "bg-green-100" : "bg-red-100"}`}>
            <div className="flex justify-between">
                <div className="flex space-x-5">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleStatusChange(false)}>
                        X
                    </button>
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleStatusChange(true)}>
                        ✓
                    </button>
                </div>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setOpenedArticle(article.articleId === openedArticle ? null : article.articleId)}></button>
            </div>
            <div className="flex flex-col space-y-3 mt-2">
                <div>
                    {article.name}
                </div>
                {
                    openedArticle === article.articleId &&
                    <div>
                        <div>
                            {article.text}
                        </div>
                        <div className="mt-5">
                            <input
                                {...register("name")}
                                className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <div className="flex justify-center items-center">
                                {/* <textarea {...register("text")}
                                    className="mt-5 w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" /> */}
                                <TextEditor register={register("text")} value={watch("text")} />
                            </div>
                            <div className="mt-5">
                                <SubmitButton label="Upravit" event={handleEdit} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}