import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "../services";
import { ShortUser } from "../models/shortUser";
import { useState } from "react";

export const Users = () => {
    const { data: usersData, isLoading, error } = useQuery<ShortUser[]>({
        queryKey: ["users"],
        queryFn: UsersApi.getUsers,
    });

    const [search, setSearch] = useState("");

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Chyba při načítání členů</div>;
    }

    const users = usersData ?? [];

    if (!Array.isArray(users)) {
        return <div>Data nejsou v požadovaném formátu.</div>;
    }

    const filteredUsers = users?.filter(user => {
        return user?.name?.toLowerCase().includes(search) || user?.surname?.toLowerCase().includes(search) || user?.email?.toLowerCase().includes(search)
            || user?.catKinds?.toLowerCase().includes(search) || user?.web?.toLowerCase().includes(search);
    });

    return (
        <div className="flex flex-col space-y-5 pb-5">
            <div className="flex flex-col relative bg-[url('../public/cats/Mendeleev_Monoid_CZ.jpg')] bg-cover bg-center p-20 bg-fixed">
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="flex flex-col text-center">
                    <div className="text-white z-10 text-5xl">
                        Členové
                    </div>
                </div>
            </div>
            <div className="lg:w-2/3 mx-auto flex flex-col px-5">
                <div className="text-5xl text-center">
                    Výbor
                </div>
                <div className="w-full text-center flex flex-col mx-auto mt-5 p-5 border border-gray-300 shadow-lg rounded-lg">
                    <div className="font-bold text-lg mb-1">SO Kočky Brno</div>
                    <div>Kabátníkova 9</div>
                    <div>602 00 Brno</div>
                    <div>IČ 03841979</div>
                    <div>číslo účtu 2600780927/2010 - Fio banka, a.s.</div>
                    <div>kocky.brno@seznam.cz</div>
                    <div>Členský příspěvek ve výši 600 Kč zasílejte na číslo účtu 2600780927/2010.</div>
                </div>

                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
                    <Member email="kmah@volny.cz" mobileNumber="+420 604 465 319" name="MVDr. Květoslava Mahelková" role="předseda" />
                    <Member email="eva@fi.muni.cz" mobileNumber="+420 605 262 990" name="Doc. RNDr. Eva Hladká, Ph.D" role="místopředseda" />
                    <Member email="hana.sulc@seznam.cz" mobileNumber="+420 604 954 118" name="Hana Hollanová" role="jednatel" />
                    <Member email="člen výboru" mobileNumber="" name="Olena Panasyuk" role="pokladník" />
                    {/* <Member email="" mobileNumber="" name="Olga Vérostová" role="člen výboru" /> */}
                    <Member email="" mobileNumber="" name="Martina Svobodová" role="webmaster" />
                </div>
                <div className="text-5xl text-center mt-10">
                    Uživatelé
                </div>
                <input onChange={(e) => setSearch(e.target.value.toLowerCase())} className="my-5 border border-black p-4 rounded" placeholder="Vyhledávač" />
                <div className="space-y-5">
                    {
                        filteredUsers?.sort((a, b) => a.surname < b.surname ? -1 : a.surname === b.surname ? 0 : 1).map((user, key) => (
                            <UserItem user={user} key={key} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

interface UserItemProps {
    user: ShortUser,
}

export const UserItem: React.FC<UserItemProps> = ({ user }) => {
    return (
        <div className="p-4 bg-white shadow rounded-md">
            <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                    <div className="font-semibold text-lg">
                        {user.name} {user.surname}
                    </div>
                    <div className="text-blue-500">
                        <a href={user.web} target="_blank" rel="noopener noreferrer">
                            {user?.web}
                        </a>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="text-gray-500">
                        {user.email}
                    </div>
                    <div className="text-gray-700">
                        {user.catKinds}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface MemberProps {
    name: string,
    email: string,
    role: string,
    mobileNumber: string,
}

export const Member: React.FC<MemberProps> = ({ email, mobileNumber, name, role }) => {
    return (
        <div className="bg-white shadow-md rounded-lg p-4 max-w-sm w-full mx-auto">
            <div className="text-xl font-semibold text-gray-800">{name}</div>
            <div className="text-sm text-gray-500">{role}</div>
            <div className="text-gray-600">{email}</div>
            <div className="text-gray-600">{mobileNumber}</div>
        </div>

    )
}