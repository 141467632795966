import { useForm } from "react-hook-form"
import { SubmitButton } from "./buttons/submitButton";
import { EntityImage } from "../models/entityImage";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase/firebase";

interface AddImagesForm {
    images: FileList,
}

interface GalleryEditorProps {
    images: EntityImage[],
    handleDelete: (imageId: number) => void,
    upload: (images: (string | null)[]) => void,
}

export const GalleryEditor: React.FC<GalleryEditorProps> = ({ images, handleDelete, upload }) => {
    const { register, watch } = useForm<AddImagesForm>();

    // function handleUpload() {
    //     upload(watch("images"));
    // }

    function handleUpload() {
        const files = watch("images");
        if (files.length === 0) {
            console.log("No files.");
            return;
        }
    
        // Convert FileList to Array to use map
        const uploadPromises = Array.from(files).map((file) => {
            const randomFileName = `${uuidv4()}-${file.name}`;
            console.log(randomFileName);
            const fileRef = ref(storage, randomFileName);
    
            return uploadBytes(fileRef, file)
                .then((snapshot) => getDownloadURL(snapshot.ref))
                .catch((error) => {
                    console.error("Upload error for file:", file.name, error);
                    return null;  // Return null if there's an error, so this file won't be included in the mutation.
                });
        });
    
        Promise.all(uploadPromises).then((urls) => {
            // Filter out any nulls in case some uploads failed.
            const validUrls = urls.filter(url => url !== null);
            if (validUrls.length > 0) {
                // Zde můžete volat mutaci pro aktualizaci URL obrázků v databázi
                upload(validUrls);
            } else {
                console.log("No images were successfully uploaded.");
            }
        }).catch((error) => {
            console.error("Error in uploading one or more files:", error);
        });
    }

    return (
        <div className="flex flex-col">
            <div className="flex justify-between">
                <div>
                    <input type="file" className="w-full" placeholder="Testy" {...register("images")} multiple accept=".png, .jpg, .jpeg, .gif" />
                </div>
                <SubmitButton label="Nahrát obrázky" event={handleUpload} />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                {images.map((image, key) => (
                    <div key={key} className="overflow-hidden relative">
                        <button
                            onClick={() => handleDelete(image.imageId)}
                            className="absolute top-2 left-2 bg-red-500 text-white p-1"
                        >
                            X
                        </button>
                        <img src={image.image} alt={`img-${key}`} className="w-full h-48 object-cover" />
                    </div>
                ))}
            </div>
        </div>
    )
}