import { FormProvider, useForm } from "react-hook-form"
import { FullUser } from "../../models/fullUser"
import { AddUser } from "./addUser"
import { useState } from "react";
import { UserEditor } from "./userEditor";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "../../services";

export interface EditUserForm {
    membershipExpire: Date,
    showOnlyExpired: boolean
}

export const UserManager = () => {
    const { data: usersData, isLoading, error } = useQuery<FullUser[]>({
        queryKey: ["users"],
        queryFn: UsersApi.getUsers,
    });

    const formMethods = useForm<EditUserForm>();
    const [editedUser, setEditedUser] = useState<number | null>(null);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading users</div>;
    }

    const users = usersData ?? [];

    if (!Array.isArray(users)) {
        return <div>Data nejsou v požadovaném formátu.</div>;
    }

    // const today = new Date();

    // const filteredUsers = formMethods.watch("showOnlyExpired") ? (users.filter(user => user.membershipExpire ? (new Date(user.membershipExpire)).getTime() > today.getTime() : true)) : users;

    return (
        <div className="flex flex-col">
            <div className="flex justify-center">
                <AddUser />
            </div>
            <FormProvider {...formMethods}>
                <div className="flex flex-col space-y-5 w-1/2 mx-auto mt-5">
                    {/* <Checkbox iconName="" valueName="showOnlyExpired" /> */}
                    {
                        users
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((user, key) => (
                                <UserItem user={user} key={key} editedUser={editedUser} setEditedUser={setEditedUser} />
                            ))
                    }
                </div>
            </FormProvider>
        </div>
    )
}

interface UserItemProps {
    user: FullUser,
    editedUser: number | null,
    setEditedUser: (id: number | null) => void,
}

export const UserItem: React.FC<UserItemProps> = ({ user, editedUser, setEditedUser }) => {
    const today = new Date();
    const date = user.membershipExpire ? new Date(user.membershipExpire) : null;

    const bg = user.membershipExpire ? ((new Date(user.membershipExpire)).getTime() < today.getTime() ? "bg-red-200" : "bg-green-200") : "bg-red-200";
    const expDate = date ? date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() : ""

    return (
        <div className={`flex flex-col`}>
            <div className={`flex justify-between ${bg} p-3`}>
                <div className="flex space-x-5">
                    <div className="flex-1">
                        {user.name} {user.surname}
                    </div>
                    <div className="flex-1">
                        {user.email}
                    </div>
                </div>
                <div className="flex space-x-5">
                    <div>
                        {expDate}
                    </div>
                    <FaEdit onClick={() => setEditedUser(editedUser === user.userId ? null : user.userId)} />
                </div>
            </div>
            <div className="mt-5">
                {
                    editedUser === user.userId &&
                    <UserEditor user={user} />
                }
            </div>
        </div>
    )
}